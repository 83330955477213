import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import privacyPolicyElement from '../components/privacyPolicy/privacyPolicyElement.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'privacyPolicyView',
  setup(__props) {

// Components

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(privacyPolicyElement)
  ]))
}
}

})