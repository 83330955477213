import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { enMessage, deMessage } from './locales/pxtDesignPortalLocales'
import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'
// @ts-ignore
import { Vue3Mq } from 'vue3-mq'
import './css/main.scss'

loadFonts()

const i18n = createI18n<false>({
  legacy: false,
  locale: 'de-DE',
  fallbackLocale: 'en-Us',
  messages: {
    'de-DE': deMessage,
    'en-Us': enMessage
  },
  warnHtmlMessage: false
})

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .use(Vue3Mq, {
    preset: 'vuetify'
  })
  .mount('#app')
