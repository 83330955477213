import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "inputAndSolutionElement__visualizationArea__progressIndicator"
}
const _hoisted_2 = {
  key: 2,
  class: "inputAndSolutionElement__visualizationArea__progressIndicator"
}
const _hoisted_3 = {
  key: 3,
  class: "text-h6"
}
const _hoisted_4 = {
  key: 4,
  class: "text-h6"
}
const _hoisted_5 = {
  key: 5,
  class: "inputAndSolutionElement__visualizationArea__appLogo"
}
const _hoisted_6 = { class: "inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo__designPortal" }

import inputElement from './inputElement/inputElement.vue'
import kochLogo from './../svgComponents/kochLogoLong.vue'
import kochSymbol from './../svgComponents/kochSymbol.vue'
import solutionVisualizationElement from './solutionsElements/solutionVisualizationElement.vue'
import { ref, Ref, watch, computed } from 'vue'
import { inputParametersType, solutionMainStructureType } from 'koch-types/src/pxtDesignPortalFrontend'
import { useStore } from 'vuex'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import { useMq, MqResponsive } from 'vue3-mq'

// makes the global store of states available in this module (store is located at ./src/store/index.ts)

export default /*@__PURE__*/_defineComponent({
  __name: 'inputAndSolutionElement',
  setup(__props) {

_useCssVars(_ctx => ({
  "49abcd2a": (responsiveCSS.value.kochSymbolWidth),
  "cab93012": (responsiveCSS.value.kochLogoWidth),
  "4171045c": (responsiveCSS.value.designPortalLetteringLineHeight),
  "30881d7b": (responsiveCSS.value.pxtLetteringFontSize),
  "7e609b72": (responsiveCSS.value.designPortalLetteringFontSize)
}))

// Imports
const store = useStore()

// makes the locales accessible in this module (locales are located at ./src/locales/pxtDesignPortalLocales.ts)
const { t } = useI18n()

// makes an Object accessible, witch supervises screen/window state in order to allow layout adaptions responsively.
const mq = useMq()

// Every variable/constant of type number, boolean, string (not Objects and Arrays) needs to be wrapped with a ref() function
// in order to make them programmatically mutable with vuejs
const introAnimation = ref(true)

// input timeout
let inputTimeout : number | undefined
let serverTimeout: number | undefined
const serverTimedOut : Ref<boolean> = ref(false)

// WATCHERS
// Makes Objects, variables etc. watchable in order to trigger sth. when the state of the object changes.
watch(store.state.inputValues, async (currVal : inputParametersType) => {
  if (store.state.effectiveMainsVoltage && store.state.useCase) {
    if (inputTimeout) {
      clearTimeout(inputTimeout)
      inputTimeout = undefined
    }
    if (!inputTimeout) {
      inputTimeout = setTimeout(async () => {
        store.commit('updateSolutions', undefined)
        const solutions = await getSolutionByInput(currVal)
        store.commit('updateSolutions', solutions)
        inputTimeout = undefined
      }, 500)
    }
  }
})

// Supervising the index of the inputElement's <v-window> Slides in order to stop animations and get
watch(store.state.slideIndex, async (currVal : {value: number}) => {
  introAnimation.value = false
  if (currVal.value === 3) {
    /* store.commit('updateSolutions', undefined) */
    const solutions = await getSolutionByInput(store.state.inputValues)
    store.commit('updateSolutions', solutions)
  }
  if (currVal.value === 2) {
    store.commit('resetInputVals')
  }
})

// FUNCTIONS

// Gets a fitting solution based on the user's input through the slider elements (found in inputElement.vue)
async function getSolutionByInput (inputRetVals : inputParametersType) {
  serverTimedOut.value = false
  const inputParams = {
    p0: inputRetVals.p0,
    p1: inputRetVals.p1,
    tZ: inputRetVals.tZ,
    tP: inputRetVals.tP,
    effectiveMainsVoltage: store.state.effectiveMainsVoltage,
    maximumMainsPower: store.state.maximumMainsPower
  }
  console.log(store.state.useCase)
  console.log(inputParams)

  serverTimeout = setTimeout(() => {
    serverTimedOut.value = true
  }, 15000)

  const solutionHTTPResponse = await axios.get('https://vpsapi.bremsenergie.de:443/api/get/solution', {
    params: {
      inputParams: inputParams,
      useCase: store.state.useCase
    }
  })

  if (solutionHTTPResponse) {
    if (serverTimeout) {
      clearTimeout(serverTimeout)
      serverTimeout = undefined
    }
  }

  return solutionHTTPResponse.data
}

// CSS RESPONSIVITY LOGIC
// In the following the mq Object will be used, which is initialized at the beginning of this
// component. The object allows to supervize the screen size and make adaptions to it for responsivity
// purposes.
// The computed function used here is a vuejs native function in order to compute property values of the
// responsiveCSS Object instead of declaring them in a fixed manner.
const responsiveCSS = computed(() : {[cssDescriptor : string] : string} => {
  const kochLogoBaseWidth = 11.5
  const kochSymbolBaseWidth = 3
  const responsiveMultiplier = 0.8
  const pxtLetteringFontSize = 2
  const designPortalLetteringFontSize = 1.55
  const designPortalLetteringLineHeight = 2
  if (mq.smMinus) {
    return {
      kochLogoWidth: (kochLogoBaseWidth * responsiveMultiplier) + 'rem',
      kochSymbolWidth: (kochSymbolBaseWidth * responsiveMultiplier) + 'rem',
      pxtLetteringFontSize: (pxtLetteringFontSize * responsiveMultiplier) + 'rem',
      designPortalLetteringFontSize: (designPortalLetteringFontSize * responsiveMultiplier) + 'rem',
      designPortalLetteringLineHeight: (designPortalLetteringLineHeight * 0.75) + 'rem'
    }
  } else {
    return {
      kochLogoWidth: kochLogoBaseWidth + 'rem',
      kochSymbolWidth: kochSymbolBaseWidth + 'rem',
      pxtLetteringFontSize: pxtLetteringFontSize + 'rem',
      designPortalLetteringFontSize: designPortalLetteringFontSize + 'rem',
      designPortalLetteringLineHeight: designPortalLetteringLineHeight + 'rem'
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    fluid: "",
    class: "inputAndSolutionElement"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, {
        justify: "center",
        "no-gutters": "",
        align: "center",
        class: "inputAndSolutionElement__visualizationArea"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "auto" }, {
            default: _withCtx(() => [
              (_unref(store).state.slideIndex.value > 2 && _unref(store).state.solutions !== undefined && _unref(store).state.solutions !== '' && _unref(store).state.solutions !== false)
                ? (_openBlock(), _createBlock(solutionVisualizationElement, { key: 0 }))
                : (_unref(store).state.slideIndex.value > 2 && _unref(store).state.solutions === undefined && serverTimedOut.value === false)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(kochSymbol, { class: "inputAndSolutionElement__visualizationArea__progressIndicator__kochSymbol" })
                    ]))
                  : (_unref(store).state.slideIndex.value > 2 && _unref(store).state.solutions === undefined && serverTimedOut.value === true)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(t)("solutionVisualizationElement.serverTimeout")), 1))
                    : ((_unref(store).state.slideIndex.value > 2 && _unref(store).state.solutions === false))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createTextVNode(_toDisplayString(_unref(t)("solutionVisualizationElement.noSolution")), 1),
                          _createElementVNode("sup", null, [
                            _createVNode(_component_v_icon, { size: "x-small" }, {
                              default: _withCtx(() => _cache[0] || (_cache[0] = [
                                _createTextVNode(" mdi-information-outline ")
                              ])),
                              _: 1
                            })
                          ]),
                          _createVNode(_component_v_tooltip, {
                            activator: "parent",
                            location: "top"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(t)("solutionVisualizationElement.tooltip.noSolution")), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : ((_unref(store).state.slideIndex.value > 2 && _unref(store).state.solutions === ''))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createTextVNode(_toDisplayString(_unref(t)("solutionVisualizationElement.noSolutionNeeded")), 1),
                            _createElementVNode("sup", null, [
                              _createVNode(_component_v_icon, { size: "x-small" }, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                  _createTextVNode(" mdi-information-outline ")
                                ])),
                                _: 1
                              })
                            ]),
                            _createVNode(_component_v_tooltip, {
                              activator: "parent",
                              location: "top"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(t)("solutionVisualizationElement.tooltip.noSolutionNeeded")), 1)
                              ]),
                              _: 1
                            })
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("div", null, [
                              _createVNode(kochSymbol, {
                                class: _normalizeClass(["inputAndSolutionElement__visualizationArea__appLogo__kochSymbol", {'inputAndSolutionElement__visualizationArea__appLogo__kochSymbol--animation': introAnimation.value}])
                              }, null, 8, ["class"]),
                              _createVNode(kochLogo, {
                                class: _normalizeClass(["inputAndSolutionElement__visualizationArea__appLogo__kochLogo", {'inputAndSolutionElement__visualizationArea__appLogo__kochLogo--animation': introAnimation.value}])
                              }, null, 8, ["class"])
                            ]),
                            _createElementVNode("div", {
                              class: _normalizeClass(["inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo", {'inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo--animation': introAnimation.value}])
                            }, [
                              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo__pxt inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo__pxt" }, "Pxt", -1)),
                              _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(t)("appBarElement.appTitle")), 1)
                            ], 2)
                          ]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, {
        align: "start",
        justify: "center",
        "no-gutters": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, null, {
            default: _withCtx(() => [
              _createVNode(inputElement, {
                class: _normalizeClass({'inputAndSolutionElement__inputElement--animation' : introAnimation.value})
              }, null, 8, ["class"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})