<template>
  <MqResponsive
      target="md+"
  >
    <v-container>
      <v-row
        align="center"
        justify="space-evenly"
      >
          <template
            v-for="(solution, i) in store.state.solutions"
            :key="i"
          >
            <v-col
              cols="auto"
            >
              <device-amount-tuple
                :deviceType="solution.type"
                :amount="solution.amount"
                :specifications="solution.specifications!"
              />
            </v-col>
            <v-col
              v-if="store.state.solutions.length > 0 && i !== (store.state.solutions.length - 1)"
              cols="auto"
            >
              <div
                class="text-h6 text-md-h5"
              >
                +
              </div>
            </v-col>
          </template>
      </v-row>
      <v-row
        justify="space-between"
      >
        <template
          v-for="(solution, i) in store.state.solutions"
          :key="i"
        >
          <v-col
            :cols="12/store.state.solutions.length"
          >
            <v-table
              density="compact"
              :max-height="responsiveCSS.vTableHeight"
            >
              <tbody>
                <tr
                  v-for="(specification, index) in Object.entries(solution.specifications as deviceSpecifications)"
                  :key="index"
                >
                  <td
                    class=" font-weight-bold text-right"
                  >
                    <span
                      v-if="specification[0].toLowerCase().includes('max')"
                      v-html="t('solutionVisualizationElement.tableSpecs.' + specification[0])"
                    ></span>
                    <span
                      v-else>
                        {{ t('solutionVisualizationElement.tableSpecs.' + specification[0]) }}
                    </span>:
                    <v-tooltip
                        v-if="(enMessage as unknown as any).solutionVisualizationElement.tooltip[specification[0]]"
                        activator="parent"
                        location="start"
                      >
                        {{t("solutionVisualizationElement.tooltip." + specification[0])}}
                      </v-tooltip>
                  </td>
                  <td
                    :class="{'flexy' : specification[0] !== 'content', 'nowrap' : specification[0] === 'dimensions'}"
                  >
                    <div>
                      {{ specification[1].value === 'accessories' ? t('solutionVisualizationElement.tableSpecs.accessories')  : specification[1].value }}
                    </div>
                    <div class="fixedy">
                      {{ specification[1].unit }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
        </template>
      </v-row>
    </v-container>
  </MqResponsive>
  <MqResponsive
    target="sm-"
  >
    <v-window
      show-arrows
    >
      <v-window-item
        v-for="(solution, i) in store.state.solutions"
        :key="i"
      >
        <v-container>
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="auto"
            >
              <device-amount-tuple
                :deviceType="solution.type"
                :amount="solution.amount"
                :specifications="solution.specifications!"
              />
            </v-col>
          </v-row>
          <v-row
            justify="center"
          >
            <v-col
              cols="auto"
            >
              <v-table
              density="compact"
              :max-height="responsiveCSS.vTableHeight"
            >
              <tbody>
                <tr
                  v-for="(specification, index) in Object.entries(solution.specifications as deviceSpecifications)"
                  :key="index"
                >
                  <td
                    class=" font-weight-bold text-right"
                  >
                    <span
                      v-if="specification[0].toLowerCase().includes('max')"
                      v-html="t('solutionVisualizationElement.tableSpecs.' + specification[0])"
                    ></span>
                    <span
                      v-else>
                        {{ t('solutionVisualizationElement.tableSpecs.' + specification[0]) }}
                    </span>
                    :
                    <v-tooltip
                        v-if="(enMessage as unknown as any).solutionVisualizationElement.tooltip[specification[0]]"
                        activator="parent"
                        location="start"
                      >
                        {{t("solutionVisualizationElement.tooltip." + specification[0])}}
                      </v-tooltip>
                  </td>
                  <td
                    :class="{'flexy' : specification[0] !== 'content'}"
                  >

                    <div>
                      {{ specification[1].value === 'accessories'
                        ? t('solutionVisualizationElement.tableSpecs.accessories')
                        : specification[1].value === 'maxEnergyInput' && store.state.effectiveMainsVoltage === 230
                          ? parseFloat(specification[1].value) * 0.85 // ACHTUNG!!!! DAS IST EIN WORKAROUND FÜR 230V-Netze, da hier andere Kondensatoren im EX verbaut werden, die eine andere Energie nach sich ziehen, Das sollte eigentlich über die Datenbank-Collection "deviceVariants" abgefangen werden, leider bestand dazu keine Zeit mehr.
                          : specification[1].value }}
                    </div>
                    <div class="fixedy">
                      {{ specification[1].unit }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-table>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
  </MqResponsive>
</template>

<script setup lang="ts">
import deviceAmountTuple from './components/deviceAmountTuple.vue'
import { enMessage } from '../../../locales/pxtDesignPortalLocales'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { deviceSpecificationParameters, deviceSpecifications, valueUnitTuple } from 'koch-types/src/pxtDesignPortalFrontend'
import { computed } from 'vue'
// @ts-ignore
import { useMq, MqResponsive } from 'vue3-mq'

// For a detailed explanation of the following 4 Objects, head to the component inputAndSolutionElement.vue
const mq = useMq()
const { t } = useI18n()
const store = useStore()

const responsiveCSS = computed(() : {[cssDescriptor : string] : string} => {
  const responsiveMultiplier = 0.8
  const vTableBaseHeight = 7
  const vTableBaseFontSize = 1
  if (mq.smMinus) {
    return {
      vTableHeight: vTableBaseHeight + 'rem',
      vTableFontSize: (vTableBaseFontSize * responsiveMultiplier) + 'rem'
    }
  } else {
    return {
      vTableHeight: vTableBaseHeight + 'rem',
      vTableFontSize: (vTableBaseFontSize * responsiveMultiplier) + 'rem'
    }
  }
})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .v-container {
    padding: 0;
  }

  .v-window {
    max-height: 45%
  }

  .v-window ::v-deep(.v-window__controls) {
    padding: 0 2px
  }

  .v-window-item {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .flexy {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .fixedy {
    width: 1.5rem;
    margin-left: 2px;
    text-align: left;
  }

  .nowrap {
    white-space: nowrap
  }

  .v-table ::v-deep(td) {
    font-size: v-bind('responsiveCSS.vTableFontSize') !important;
  }

  .v-table ::v-deep(.v-table__wrapper) {
    overflow-x:hidden
  }
</style>
