import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "amountIllustrationTuple__hoverElement"
}
const _hoisted_2 = { class: "amountIllustrationTuple__amount text-h5" }
const _hoisted_3 = { class: "amountIllustrationTuple__illustration" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  class: "amountIllustrationTuple__illustration__deviceName--text--md+ text-body text-center"
}

import { useMq } from 'vue3-mq'
import { useI18n } from 'vue-i18n'
import { computed, toRefs } from 'vue'
import { deviceSpecifications } from 'koch-types/src/pxtDesignPortalFrontend'

// helpers
// For a detailed explanation of the following 2 Objects, head to the component inputAndSolutionElement.vue

export default /*@__PURE__*/_defineComponent({
  __name: 'deviceAmountTuple',
  props: {
    deviceType: {},
    amount: {},
    specifications: {}
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "a021ad50": (responsiveCSS.value.alignItems)
}))


// @ts-ignore
const mq = useMq()
const { t, locale } = useI18n()

// defines properties specific to this component
const props = __props

// makes properties programmatically mutable (its a vue specific thing, watch inside inputAndSolutionsElement.vue for more info)
const { deviceType, amount, specifications } = toRefs(props)

function getDeviceNameImgUrl (deviceType : string) {
  return require(`@/assets/products/logos/${deviceType}_Logo.png`)
}

function forwardToProductPage (deviceType : string, locale : string) {
  const linkToProduct = 'https://bremsenergie.de/' + locale + '/' + deviceType
  window.open(linkToProduct)
}

function getDeviceDisplayName (deviceType : string) {
  let displayName
  if (deviceType === 'accessories') {
    displayName = t('solutionVisualizationElement.' + deviceType)
  }
  switch (deviceType) {
    case 'DLC 90V/10F':
      displayName = 'DSKM-090010-FCF'
      break
    case 'DLC 45V/165F':
      displayName = 'DSKM-045165-FCF'
      break
    case 'PxtEx':
      displayName = 'PxtEx'
      break
    case 'PxtRx':
      displayName = 'PxtRx'
      break
    case 'PxtFx':
      displayName = 'PxtFx'
      break
  }

  return displayName
}

function getDeviceIllustrationImgUrl (deviceType : string) {
  const integratedCapsNumber = specifications.value.amountOfIntegratedCaps ? parseInt(specifications.value.amountOfIntegratedCaps.value) : 0
  let deviceIllustrationImgName = 'unknown'
  switch (deviceType) {
    case 'DLC 90V/10F':
      deviceIllustrationImgName = 'DLC9010'
      break
    case 'DLC 90V/20F':
      deviceIllustrationImgName = 'DLC9020'
      break
    case 'DLC 45V/165F':
      deviceIllustrationImgName = 'DLC45165'
      break
    case 'DLC 45V/330F':
      deviceIllustrationImgName = 'DLC45330'
      break
    case 'PxtRx':
      deviceIllustrationImgName = deviceType
      break
    case 'PxtMx':
      deviceIllustrationImgName = deviceType
      break
    case 'accessories':
      deviceIllustrationImgName = deviceType
      break
    case 'PxtEx':
    case 'PxtFx':
      if (integratedCapsNumber) {
        if (integratedCapsNumber >= 1 && integratedCapsNumber <= 3) {
          deviceIllustrationImgName = deviceType + '_' + integratedCapsNumber
        } else if (integratedCapsNumber > 3) {
          deviceIllustrationImgName = deviceType + '_' + '3'
        } else {
          deviceIllustrationImgName = deviceType + '_' + '1'
        }
      } else {
        break
      }
  }
  return require(`@/assets/products/illustrations/${deviceIllustrationImgName}.png`)
}

// methods
// For a detailed explanation head to inputAndSolutionElement.vue
const responsiveCSS = computed(() : {[thingName : string] : string} => {
  if (mq.smMinus) {
    return {
      alignItems: 'center',
      flexDirection: 'row',
      marginRight: '0.5rem'
    }
  } else {
    return {
      alignItems: 'center',
      flexDirection: 'column',
      marginRight: '0'
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_fade_transition = _resolveComponent("v-fade-transition")!
  const _component_v_hover = _resolveComponent("v-hover")!

  return (_openBlock(), _createBlock(_component_v_hover, null, {
    default: _withCtx(({ isHovering, props }) => [
      _createElementVNode("div", _mergeProps({ class: "amountIllustrationTuple" }, props), [
        _createVNode(_component_v_fade_transition, null, {
          default: _withCtx(() => [
            (isHovering && _unref(deviceType).startsWith('Pxt'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_v_btn, {
                    icon: "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (forwardToProductPage(_unref(deviceType), _unref(locale))))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_tooltip, {
                        activator: "parent",
                        location: "bottom"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('solutionVisualizationElement.tooltip.productPage')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, null, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode(" mdi-arrow-right ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(amount)) + "x ", 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "amountIllustrationTuple__illustration__deviceImage",
            src: getDeviceIllustrationImgUrl(_unref(deviceType))
          }, null, 8, _hoisted_4),
          (!_unref(deviceType).startsWith('DLC') && _unref(deviceType) !== 'accessories')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "amountIllustrationTuple__illustration__deviceName--v-img--md+",
                src: getDeviceNameImgUrl(_unref(deviceType))
              }, null, 8, _hoisted_5))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(getDeviceDisplayName(_unref(deviceType)) === 'accessories' ? _unref(t)('solutionVisualizationElement.' + getDeviceDisplayName(_unref(deviceType))) : getDeviceDisplayName(_unref(deviceType))), 1))
        ])
      ], 16)
    ]),
    _: 1
  }))
}
}

})