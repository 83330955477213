<template>
    <v-container
      fluid
      class="inputAndSolutionElement"
    >
      <v-row
        justify="center"
        no-gutters
        align="center"
        class="inputAndSolutionElement__visualizationArea"
      >
        <v-col
          cols="auto"
        >
          <template
            v-if="store.state.slideIndex.value > 2 && store.state.solutions !== undefined && store.state.solutions !== '' && store.state.solutions !== false"
          >
            <solution-visualization-element/>
          </template>
          <div
            v-else-if="store.state.slideIndex.value > 2 && store.state.solutions === undefined && serverTimedOut === false"
            class="inputAndSolutionElement__visualizationArea__progressIndicator"
          >
            <koch-symbol
              class="inputAndSolutionElement__visualizationArea__progressIndicator__kochSymbol"
            />
          </div>
          <div
            v-else-if="store.state.slideIndex.value > 2 && store.state.solutions === undefined && serverTimedOut === true"
            class="inputAndSolutionElement__visualizationArea__progressIndicator"
          >
            {{t("solutionVisualizationElement.serverTimeout")}}
          </div>
          <div
            v-else-if="(store.state.slideIndex.value > 2 && store.state.solutions === false)"
            class="text-h6"
          >
            {{t("solutionVisualizationElement.noSolution")}}<sup>
              <v-icon
                size="x-small"
              >
                mdi-information-outline
              </v-icon>
            </sup>
          <v-tooltip
            activator="parent"
            location="top"
          >{{t("solutionVisualizationElement.tooltip.noSolution")}}
          </v-tooltip>
          </div>
          <div
            v-else-if="(store.state.slideIndex.value > 2 && store.state.solutions === '')"
            class="text-h6"
          >
            {{t("solutionVisualizationElement.noSolutionNeeded")}}<sup>
              <v-icon
                size="x-small"
              >
                mdi-information-outline
              </v-icon>
            </sup>
          <v-tooltip
            activator="parent"
            location="top"
          >{{t("solutionVisualizationElement.tooltip.noSolutionNeeded")}}
          </v-tooltip>
          </div>
          <div
            v-else
            class="inputAndSolutionElement__visualizationArea__appLogo"
          >
            <div>
              <koch-symbol
                class="inputAndSolutionElement__visualizationArea__appLogo__kochSymbol"
                :class="{'inputAndSolutionElement__visualizationArea__appLogo__kochSymbol--animation': introAnimation}"
              />
              <koch-logo
                class="inputAndSolutionElement__visualizationArea__appLogo__kochLogo"
                :class="{'inputAndSolutionElement__visualizationArea__appLogo__kochLogo--animation': introAnimation}"
              />
            </div>
            <div
              class="inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo"
              :class="{'inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo--animation': introAnimation}"
            >
              <span class="inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo__pxt inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo__pxt">Pxt</span>
              <span
                class="inputAndSolutionElement__visualizationArea__appLogo__designPortalLogo__designPortal"
              >{{t("appBarElement.appTitle")}}</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        align="start"
        justify="center"
        no-gutters
      >
        <v-col>
          <input-element
            :class="{'inputAndSolutionElement__inputElement--animation' : introAnimation}"
          />
        </v-col>
      </v-row>
    </v-container>
</template>

<script setup lang="ts">
// Imports
import inputElement from './inputElement/inputElement.vue'
import kochLogo from './../svgComponents/kochLogoLong.vue'
import kochSymbol from './../svgComponents/kochSymbol.vue'
import solutionVisualizationElement from './solutionsElements/solutionVisualizationElement.vue'
import { ref, Ref, watch, computed } from 'vue'
import { inputParametersType, solutionMainStructureType } from 'koch-types/src/pxtDesignPortalFrontend'
import { useStore } from 'vuex'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import { useMq, MqResponsive } from 'vue3-mq'

// makes the global store of states available in this module (store is located at ./src/store/index.ts)
const store = useStore()

// makes the locales accessible in this module (locales are located at ./src/locales/pxtDesignPortalLocales.ts)
const { t } = useI18n()

// makes an Object accessible, witch supervises screen/window state in order to allow layout adaptions responsively.
const mq = useMq()

// Every variable/constant of type number, boolean, string (not Objects and Arrays) needs to be wrapped with a ref() function
// in order to make them programmatically mutable with vuejs
const introAnimation = ref(true)

// input timeout
let inputTimeout : number | undefined
let serverTimeout: number | undefined
const serverTimedOut : Ref<boolean> = ref(false)

// WATCHERS
// Makes Objects, variables etc. watchable in order to trigger sth. when the state of the object changes.
watch(store.state.inputValues, async (currVal : inputParametersType) => {
  if (store.state.effectiveMainsVoltage && store.state.useCase) {
    if (inputTimeout) {
      clearTimeout(inputTimeout)
      inputTimeout = undefined
    }
    if (!inputTimeout) {
      inputTimeout = setTimeout(async () => {
        store.commit('updateSolutions', undefined)
        const solutions = await getSolutionByInput(currVal)
        store.commit('updateSolutions', solutions)
        inputTimeout = undefined
      }, 500)
    }
  }
})

// Supervising the index of the inputElement's <v-window> Slides in order to stop animations and get
watch(store.state.slideIndex, async (currVal : {value: number}) => {
  introAnimation.value = false
  if (currVal.value === 3) {
    /* store.commit('updateSolutions', undefined) */
    const solutions = await getSolutionByInput(store.state.inputValues)
    store.commit('updateSolutions', solutions)
  }
  if (currVal.value === 2) {
    store.commit('resetInputVals')
  }
})

// FUNCTIONS

// Gets a fitting solution based on the user's input through the slider elements (found in inputElement.vue)
async function getSolutionByInput (inputRetVals : inputParametersType) {
  serverTimedOut.value = false
  const inputParams = {
    p0: inputRetVals.p0,
    p1: inputRetVals.p1,
    tZ: inputRetVals.tZ,
    tP: inputRetVals.tP,
    effectiveMainsVoltage: store.state.effectiveMainsVoltage,
    maximumMainsPower: store.state.maximumMainsPower
  }
  console.log(store.state.useCase)
  console.log(inputParams)

  serverTimeout = setTimeout(() => {
    serverTimedOut.value = true
  }, 15000)

  const solutionHTTPResponse = await axios.get('https://vpsapi.bremsenergie.de:443/api/get/solution', {
    params: {
      inputParams: inputParams,
      useCase: store.state.useCase
    }
  })

  if (solutionHTTPResponse) {
    if (serverTimeout) {
      clearTimeout(serverTimeout)
      serverTimeout = undefined
    }
  }

  return solutionHTTPResponse.data
}

// CSS RESPONSIVITY LOGIC
// In the following the mq Object will be used, which is initialized at the beginning of this
// component. The object allows to supervize the screen size and make adaptions to it for responsivity
// purposes.
// The computed function used here is a vuejs native function in order to compute property values of the
// responsiveCSS Object instead of declaring them in a fixed manner.
const responsiveCSS = computed(() : {[cssDescriptor : string] : string} => {
  const kochLogoBaseWidth = 11.5
  const kochSymbolBaseWidth = 3
  const responsiveMultiplier = 0.8
  const pxtLetteringFontSize = 2
  const designPortalLetteringFontSize = 1.55
  const designPortalLetteringLineHeight = 2
  if (mq.smMinus) {
    return {
      kochLogoWidth: (kochLogoBaseWidth * responsiveMultiplier) + 'rem',
      kochSymbolWidth: (kochSymbolBaseWidth * responsiveMultiplier) + 'rem',
      pxtLetteringFontSize: (pxtLetteringFontSize * responsiveMultiplier) + 'rem',
      designPortalLetteringFontSize: (designPortalLetteringFontSize * responsiveMultiplier) + 'rem',
      designPortalLetteringLineHeight: (designPortalLetteringLineHeight * 0.75) + 'rem'
    }
  } else {
    return {
      kochLogoWidth: kochLogoBaseWidth + 'rem',
      kochSymbolWidth: kochSymbolBaseWidth + 'rem',
      pxtLetteringFontSize: pxtLetteringFontSize + 'rem',
      designPortalLetteringFontSize: designPortalLetteringFontSize + 'rem',
      designPortalLetteringLineHeight: designPortalLetteringLineHeight + 'rem'
    }
  }
})
</script>

<style scoped lang="scss">

  // ANIMATIONS
  @keyframes appLogoIntro {
    0% {
      opacity: 0;
      top: 150px;
    }
    75% {
      opacity: 1;
      top: 150px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes inputElementIntro {
    0% {
      opacity: 0;
      bottom: -150px;
    }
    100% {
      opacity: 1;
      bottom: 0;
    }
  }

  @keyframes appLogoSubIntro {
    0% {
      opacity: 0;
      top: 0
    }
    50% {
      opacity: 0;
      top: 0
    }
    75% {
      opacity: 1;
      top: 150px
    }
    100% {
      opacity: 1;
      top:0;
    }
  }

  @keyframes progressAnimation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .inputAndSolutionElement {
    &__visualizationArea {
      height: 50%;
      &__progressIndicator {
        display: flex;
        align-items: center;
        height: 15rem;
        &__kochSymbol {
          width: 4rem;
          animation-name: progressAnimation;
          animation-duration: 1.5s;
          animation-iteration-count: infinite;
        }
      }

      &__appLogo {
        text-align: center;
        &__kochSymbol {
          height: auto;
          width: v-bind('responsiveCSS.kochSymbolWidth');
          margin-right: 1rem;
          &--animation {
            position: relative;
            animation-name: appLogoIntro;
            animation-duration: 2s;
          }
        }
        &__kochLogo {
          width: v-bind('responsiveCSS.kochLogoWidth');
          &--animation {
            position: relative;
            animation-name: appLogoIntro;
            animation-duration: 2s;
          }
        }
        &__designPortalLogo {
          white-space: nowrap;
          overflow: hidden;
          line-height: v-bind('responsiveCSS.designPortalLetteringLineHeight');
          &--animation {
            position: relative;
            animation-name: appLogoSubIntro;
            animation-duration: 2s;
          }
          &__pxt {
            font-family: 'Engravers\' Gothic BT';
            font-size: v-bind('responsiveCSS.pxtLetteringFontSize')
          }
          &__designPortal {
            font-size: v-bind('responsiveCSS.designPortalLetteringFontSize')
          }
        }
      }
    }

    &__inputElement {
      &--animation {
        position: relative;
        animation-name: inputElementIntro;
        animation-duration: 1s;
        animation-delay: 2s;
        animation-fill-mode: forwards;
        opacity: 0;
      }
    }
  }
</style>
