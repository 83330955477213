import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "appBar__wrapper" }
const _hoisted_2 = { class: "appBar__title" }
const _hoisted_3 = { class: "appBar__title__designPortal" }
const _hoisted_4 = ["onClick"]

import { useI18n } from 'vue-i18n'
import kochLogo from './components/svgComponents/kochLogoLong.vue'
import kochSymbol from './components/svgComponents/kochSymbol.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { t, locale } = useI18n()

function goToHomepage () {
  const baseLinkToHomepage = 'https://auslegungsportal.bremsenergie.de/'
  window.open(baseLinkToHomepage)
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, { class: "appBar" }, {
        append: _withCtx(() => [
          _createElementVNode("div", null, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['de','en'], (language, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                _createElementVNode("span", {
                  onClick: () => {locale.value = ['de-DE', 'en-Us'][i]},
                  class: _normalizeClass({'text-decoration-underline' : _unref(locale) === ['de-DE', 'en-Us'][i]} ),
                  id: "language"
                }, _toDisplayString(language.toUpperCase()), 11, _hoisted_4),
                (i === 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(" | ")
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            }), 64)),
            _createVNode(_component_v_btn, {
              icon: "mdi-dots-vertical",
              id: "menu-activator"
            }),
            _createVNode(_component_v_menu, {
              activator: "#menu-activator",
              location: "start"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_list, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, { to: "/privacy-policy" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('appBarElement.settings.privacyPolicy')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_list_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_link, { to: "/legal-disclosure" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)('appBarElement.settings.impressum')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_title, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_router_link, {
                  class: "appBar__logo",
                  to: "/"
                }, {
                  default: _withCtx(() => [
                    _createVNode(kochSymbol, { class: "appBar__logo__kochSymbol" }),
                    _createVNode(kochLogo, { class: "appBar__logo__kochLogo" })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "appBar__title__pxt" }, "Pxt", -1)),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(t)("appBarElement.appTitle")), 1)
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})