<template>
  <template
      v-if="locale === 'de-DE'"
  >
    <div
      class="ppWrapper"
    >
      <h2>Datenschutzerklärung: App Pxt-Auslegungsportal</h2>

      <h4>Stand August 2022</h4>

      <h4>Allgemeine Hinweise - Datenschutz auf einen Blick</h4>

      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie unsere App Pxt-Auslegungsportal
        nutzen. Personenbezogene Daten sind Daten, mit denen Sie persönlich
        identifiziert werden können. Der Schutz Ihrer personenbezogenen Daten ist
        uns wichtig. Nach der EU-Datenschutz-Grundverordnung (Artikel 13 DSGVO) sind
        wir verpflichtet, Sie darüber zu informieren, zu welchem Zweck wir Daten
        erheben, speichern oder weiterleiten und welche Rechte Sie beim Datenschutz
        haben. Der Inhalt und Umfang der Datenverarbeitung richten sich maßgeblich
        nach den jeweils von Ihnen zur Verfügung gestellten Daten bzw. den für die
        Geschäftsbeziehung oder die Kommunikation erforderlichen Daten. Ausführliche
        Informationen zum Thema Datenschutz entnehmen Sie unserer hier aufgeführten
        Datenschutzerklärung.
      </p>

      <h2>Datenerfassung in der App Pxt-Auslegungsportal</h2>

      <h4>
        Wer ist für die Datenverarbeitung verantwortlich und an wen kann ich mich
        wenden?
      </h4>

      <p>
        Die Datenverarbeitung in der App Pxt-Auslegungsportal erfolgt durch den
        App-Betreiber. Die Kontaktdaten des Verantwortlichen und die zuständige
        Aufsichtsbehörde finden Sie im Impressum dieser App sowie in dieser
        Datenschutzerklärung unter dem Punkt "Verantwortliche Stelle".
      </p>

      <h4>
        Wie erfassen wir Ihre Daten, aus welchen Quellen stammen diese Daten und
        wofür nutzen wir diese?
      </h4>

      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen.
        Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Suchfeld
        oder Kontakformular eingeben. Andere Daten werden automatisch&nbsp;durch die
        IT-Systeme unseres Webservers bei der Kommunikation mit diesem erfasst. Dies
        geschieht beispielsweise, wenn die App Gerätedaten über den Server
        anfragt.&nbsp;Die erhobenen Daten sind vor allem technische Daten
        (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs).
        Die Erfassung dieser Daten erfolgt automatisch, sobald Sie Daten vom
        Webserver abfragen. Ein Teil der Daten wird erhoben, um eine fehlerfreie
        Bereitstellung der Website zu gewährleisten.
      </p>

      <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>

      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten.
        Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu
        verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
        können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
        haben Sie das Recht, unter bestimmten Umständen die Einschränkung der
        Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht
        Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu
        sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit
        mithilfe der im Impressum und auch in dieser
        Datenschutzerklärung&nbsp;angegebenen Adresse an uns wenden.
      </p>

      <h4>Installation der App Pxt-Auslegungsportal</h4>

      <p>
        Die App Pxt-Auslegungsportal&nbsp;ist über von Dritten betriebene
        Vertriebsplattformen erhältlich, sogenannte App-Stores (Google Play und
        Apple App Store). Ihr Download setzt gegebenenfalls eine vorherige
        Registrierung beim jeweiligen App-Store und die Installation der
        App-Store-Software voraus. Wir haben&nbsp;keinen Einfluss auf die Erhebung,
        Verarbeitung und Nutzung von personenbezogenen Daten im Zusammenhang mit
        Ihrer Registrierung und der Bereitstellung von Downloads in dem jeweiligen
        App-Store und der App-Store-Software. Verantwortliche Stelle ist insoweit
        allein der Betreiber des jeweiligen App-Stores. Bitte informieren Sie sich
        bei Bedarf direkt bei dem jeweiligen AppStore-Anbieter.
      </p>

      <h4>Erforderliche Berechtigungen</h4>

      <p>
        Damit die App ordnungsgemäß funktionieren kann, ist es notwendig, dass Sie
        den Zugriff auf bestimmte Smartphone-Funktionen gewähren, die auf dem Gerät
        gespeichert werden. So werden Sie einmalig zu Beginn oder auch erst bei
        Nutzung der jeweiligen Funktion aufgefordert, die entsprechende
        Zugriffsberechtigung zu erteilen.
      </p>

      <ol>
        <li>
          Netzwerkzugriff &amp; Netzverbindungen (zur Datenabfrage auf dem
          Webserver)
        </li>
        <li>Kamerazugriff (zur Erfassung eines QR-Codes)</li>
        <li>Speicher (zur lokalen Speicherung bereits abgerufener Gerätedaten)</li>
      </ol>

      <h4>Welche Daten sind zur Nutzung des Dienstes erforderlich?</h4>

      <p>
        Die App Pxt-Auslegungsportal erhebt und speichert keine personenbezogenen Daten. Zur
        Nutzung des Dienstes ist es aber notwendig, dass Sie die Seriennummer und
        die Typenbezeichnung Ihres Gerätes über die zur Verfügung stehenden
        Suchmechanismen an unseren Webserver übertragen. Ohne die Angabe dieser
        Daten können Sie den Dienst nicht nutzen.
      </p>

      <h4>Erhält die Michael Koch GmbH oder Dritte Daten und zu welchem Zweck?</h4>

      <p>
        Lediglich die übermittelten Gerätedaten werden an unseren Server übertragen,
        um dort eine Datenbankabfrage auszulösen. Die Daten werden nicht gespeichert
        und nicht an Dritte übermittelt.
      </p>

      <h4>Speicherfristen und Löschung der genutzten Daten</h4>

      <p>
        Bei der aktiven Nutzung der App werden die einmal vom Webserver abgerufenen
        Gerätedaten bis zur Deinstallation oder bis zum Leeren des Speicherbereichs
        der App gespeichert. Auf dem Webserver werden lediglich die in den Server
        Log Dateien automatisiert erhobenen Daten gespeichert (siehe "Server Log
        Dateien").
      </p>

      <h4>Externes Hosting</h4>

      <p>
        Der Webserver, auf dem die Datenbank zur App betrieben wird, wird bei einem
        externen Dienstleister gehostet (Hoster). Die personenbezogenen Daten, die
        auf dieser Website erfasst werden, werden auf den Servern des Hosters
        gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen,
        Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
        Websitezugriffe und sonstige Daten, die über eine Website generiert werden,
        handeln. Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
        gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b
        DSGVO) und im Interesse einer sicheren, schnellen und effizienten
        Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter
        (Art. 6 Abs. 1 lit. f DSGVO). Unser Hoster wird Ihre Daten nur insoweit
        verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich
        ist und unsere Weisungen in Bezug auf diese Daten befolgen.
      </p>

      <h4>Abschluss eines Vertrages über Auftragsverarbeitung</h4>

      <p>
        Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen
        Vertrag über Auftragsverarbeitung mit unserem Hoster geschlossen.
      </p>

      <p>&nbsp;</p>

      <h4>Allgemeine Hinweise und Pflichtinformationen</h4>

      <h4>Datenschutz</h4>

      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr
        ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene
        personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen
        Sie persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie
        nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht. Wir
        weisen darauf hin, dass die Datenübertragung im Internet (z.&nbsp;B. bei der
        Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
        Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
      </p>

      <h4>Verantwortliche Stelle</h4>

      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:<br />
        Michael Koch GmbH<br />
        Zum Grenzgraben 28<br />
        76698 Ubstadt-Weiher<br />
        Tel: +49 72 51-96 26-200<br />
        E-Mail:
        <a href="mailto:mail@bremsenergie.de" target="_blank"
          >mail@bremsenergie.de</a
        ><br />
        Verantwortliche Stelle ist die natürliche oder juristischen Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (siehe Absatz Datenschutz)
        entscheidet.
      </p>

      <h4>Gesetzlich vorgeschriebener Datenschutzbeauftragter</h4>

      <p>
        Wir haben einen Datenschutzbeauftragten bestellt:<br />
        Dr. Christian Wolff<br />
        <span
          ><span
            ><span
              ><span
                ><span
                  ><span
                    ><span
                      ><span><span>Michael Koch GmbH</span></span></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          ></span
        ><br />
        <span
          ><span
            ><span
              ><span
                ><span
                  ><span
                    ><span
                      ><span><span>Zum Grenzgraben 28</span></span></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          ></span
        ><br />
        <span
          ><span
            ><span
              ><span
                ><span
                  ><span
                    ><span
                      ><span><span>76698 Ubstadt-Weiher</span></span></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          ></span
        ><br />
        <span
          ><span
            ><span
              ><span
                ><span
                  ><span
                    ><span
                      ><span><span>Tel: +49 72 51-96 26-200</span></span></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          ></span
        >
      </p>

      <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>

      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>

      <h4>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
        Direktwerbung (Art. 21 DSGVO)
      </h4>

      <p>
        Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. E oder F
        DSGVO erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus
        Ihrer besonderen Situation ergeben, gegen die Verarbeitung Ihrer
        personenbezogenen Daten Widerspruch einzulegen. Dies gilt auch für ein auf
        diese Bestimmungen gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf
        denen eine Verarbeitung beruht, entnehmen Sie dieser Datenschutzerklärung,
        wenn Sie Widerspruch einlegen, werden wir Ihre betroffenen personenbezogenen
        Daten nicht mehr verarbeiten, es sei denn, wir können zwingende
        schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
        Rechte und Freiheiten überwiegen oder die Verarbeitung dient der
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen (Widerspruch
        nach Art. 21 Abs. 1 DSGVO). Werden Ihre personenbezogenen Daten verarbeitet,
        um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch
        gegen die Verarbeitung Sie betreffender personenbezogenen Daten zum Zwecke
        derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es
        mit solcher Direktwerbung in Verbindung steht, wenn Sie widersprechen,
        werden Ihre personenbezogenen Daten anschließend nicht mehr zum Zwecke der
        Direktwerbung verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
      </p>

      <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>

      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des
        Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet
        anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
      </p>

      <h4>Recht auf Datenübertragbarkeit</h4>

      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in
        Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen
        Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen.
        Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
        ist.
      </p>

      <h4>SSL- bzw. TLS-Verschlüsselung</h4>

      <p>
        Die Webseite, die die Anfragen der App entgegen nimmt,&nbsp;nutzt aus
        Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie
        zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
        senden, eine SSL- bzw. TLS-Verschlüsselung. Wenn die SSL- bzw.
        TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
        übermitteln, nicht von Dritten mitgelesen werden.
      </p>

      <h4>Auskunft, Löschung und Berechtigung</h4>

      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
        Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen
        Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und
        ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
        weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
        unter der im Impressum angegebenen Adresse an uns wenden.
      </p>

      <h4>Recht auf Einschränkung der Verarbeitung</h4>

      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter
        der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
        Einschränkung der Verarbeitung besteht in folgenden Fällen:
      </p>

      <p>
        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
        bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die
        Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung
        Ihrer personenbezogenen Daten zu verlangen.<br />
        Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
        geschah/geschieht, können Sie statt der Löschung die Einschränkung der
        Datenverarbeitung verlangen. Wenn wir Ihre personenbezogenen Daten nicht
        mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder
        Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt
        der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen
        Daten zu verlangen.<br />
        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss
        eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden.
        Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das
        Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen.
      </p>

      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben,
        dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer
        Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
        juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses
        der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
      </p>

      <h4>Wie lange werden meine Daten gespeichert?<strong> </strong></h4>

      <p>
        Wir speichern Ihre Daten nur solange, wie es für die Bearbeitung des
        Geschäftsvorfalls oder zur Einhaltung von Dokumentationspflichten
        erforderlich ist. Gesetzliche Vorgaben verlangen, dass Handelsbriefe
        mindestens 6 Jahre, mit steuerlich relevantem Inhalt mindestens 10 Jahre
        aufbewahrt werden. Unsere IT-Systeme verfügen darüber hinaus über
        umfangreiche Datensicherungskonzepte, die ein vorzeitiges Löschen nicht
        zulassen. In diesem Falle wird der Zugriff auf Daten nach Ablauf der
        erlaubten Speicherfrist eingeschränkt. Bewerbungsunterlagen werden 6 Monate
        nach Abschluss der Stellenbesetzung gespeichert oder aufbewahrt, erfolgt die
        Bewerbung über eine Personalvermittlung, müssen Stammdaten länger aufbewahrt
        werden.
      </p>

      <h4>Wie sicher sind meine Daten?<strong> </strong></h4>

      <p>
        Wir setzen dem Stand der Technik entsprechende Sicherheitsmaßnahmen ein, um
        Ihre Daten angemessen zu schützen. Unsere Mitarbeiter/innen, unsere
        Subunternehmer und Handelspartner sind verpflichtet, unsere Richtlinien zur
        Informationssicherheit zu beachten.
      </p>

      <h4>Server Log Dateien</h4>

      <p>
        Der Provider des Webservers erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:<br />
        Browsertyp und Browserversion<br />
        verwendetes Betriebssystem<br />
        Referrer URL<br />
        Hostname des zugreifenden Rechners<br />
        Uhrzeit der Serveranfrage<br />
        IP-Adresse<br />
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen. Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
        der technisch fehlerfreien Darstellung und der Optimierung seiner Website –
        hierzu müssen die Server-Log-Files erfasst werden.
      </p>

      <h4>Anfrage per E-Mail, Telefon oder Fax</h4>

      <p>
        Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
        Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name,
        Anfrage, etc.) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
        gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf Grundlage von
        Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
        Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde. Die
        von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns,
        bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
        widerrufen oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B. nach
        abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche
        Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
        unberührt.
      </p>

      <h4>Kontaktformular</h4>

      <p>
        Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
        Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
        Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
        Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
        Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf Grundlage von
        Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
        Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde. Die
        von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie
        uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen
        oder der Zweck für die Datenspeicherung entfällt (z.&nbsp;B. nach
        abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
        Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
      </p>

      <h4>Widerspruch Werbe-Mails<strong> </strong>&nbsp;</h4>

      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung
        und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der
        Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
        unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
      </p>

      <h4>Widerspruch</h4>

      <p>
        Der Datenerhebung und -speicherung kann jederzeit mit Wirkung für die
        Zukunft widersprochen werden. Sie können jederzeit Ihre Einwilligung zur
        Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten für die
        Zukunft widerrufen. Wenden Sie sich in diesen Fällen bitte entweder an die
        im Impressum angegebene Adresse oder an
        <a href="mailto:mail@bremsenergie.de." target="_blank"
          >mail@bremsenergie.de.</a
        >
        Die Löschung der personenbezogenen Daten erfolgt, wenn Sie die Einwilligung
        zur Speicherung widerrufen, wenn die Kenntnis der personenbezogenen Daten
        zur Erfüllung des mit der Speicherung verfolgten Zwecks nicht mehr
        erforderlich ist oder wenn die Speicherung aus sonstigen gesetzlichen
        Gründen unzulässig ist. Daten, die für Abrechnungs- und Buchhaltungszwecke
        erforderlich sind oder der gesetzlichen Aufbewahrungspflicht unterliegen,
        sind davon nicht berührt.
      </p>

      <h4>Sicherheit</h4>

      <p>
        Die Michael Koch GmbH trifft technische und organisatorische
        Sicherheitsmaßnahmen, um Ihre personenbezogenen Daten gegen unbeabsichtigte
        oder unrechtmäßige Löschung, Veränderung oder gegen Verlust und gegen
        unberechtigte Weitergabe oder unberechtigten Zugriff zu schützen.
      </p>

      <h4>Kontakt<strong> </strong>&nbsp;</h4>

      <p>
        Für Informationen, Auskunft, Anregungen und Beschwerden hinsichtlich der
        Verarbeitung Ihrer personenbezogenen Daten wenden Sie sich bitte an die
        allgemeine Kontaktadresse:
        <a href="mailto:mail@bremsenergie.de." target="_blank"
          >mail@bremsenergie.de.</a
        >
        Sollten trotz unserer vielseitigen Bemühungen um Datenrichtigkeit und
        Aktualität falsche Informationen gespeichert sein, werden wir diese auf Ihre
        Aufforderung hin berichtigen.
      </p>

      <h4>Anpassung der Datenschutzerklärung</h4>

      <p>
        Bitte beachten Sie, dass wir bei Bedarf Anpassungen an dieser Information
        nach Art 13/14 DSGVO zur Datenverarbeitung vornehmen können bzw. müssen. Die
        jeweils aktuelle Version dieser Information nach Art 13/14 DSGVO finden Sie
        jederzeit auf unserer Homepage.
      </p>
    </div>
  </template>
  <template
    v-else
  >
    <div
      class="ppWrapper"
    >
      <h2>Privacy Policy: PxtTypeplate</h2>

      <h4>As of February 2021</h4>

      <h4>General Information on this website</h4>

      <p>
        The following notes provide a simple overview of what happens to your
        personal data when you visit our website. Personal data is data with which
        you can be personally identified. The protection of your personal data is
        important to us. According to the EU General Data Protection Regulation
        (Article 13 DSGVO (DPA)), we are obliged to inform you about the purpose for
        which we collect, store or forward data and what your rights are in data
        protection. The content and scope of the data processing is largely
        determined by the data you provide or the data required for the business
        relationship or communication. You can find detailed information on the
        subject of data protection in our data protection declaration listed here.
      </p>

      <h3>Data collection on this website</h3>

      <h4>Who is responsible for data processing and who can I contact?</h4>

      <p>
        The data processing on this app is carried out by the website operator. The
        contact data of the responsible person and the responsible supervisory
        authority can be found in the legal notice of the app as well as inside this
        privacy policy under "Responsible Party".
      </p>

      <h4>
        Which data are processed and from which sources does this data originate and
        what do we use it for?
      </h4>

      <p>
        On the one hand, your data is collected when you provide it to us. This can
        be data that you enter in a search field or in a&nbsp;contact form, for
        example. Other data is automatically collected by the IT systems of our web
        server during communication with it.&nbsp;This happens, for example, when
        the app requests device data via the server.&nbsp; These are mainly
        technical data (e.g. Internet browser, operating system or time you spend on
        the webserver). This data is collected automatically as soon as you enter
        this web server. Part of the data is collected to ensure that the website is
        provided without errors.
      </p>

      <h4>What rights do you have regarding your data?</h4>

      <p>
        You have the right to receive information free of charge at any time about
        the origin, recipient and purpose of your stored personal data. You also
        have the right to demand the correction or deletion of this data. If you
        have given your consent to data processing, you can revoke this consent for
        the future at any time. In addition, you have the right to demand, under
        certain circumstances, the restriction of the processing of your personal
        data. Furthermore, you have the right of appeal to the responsible
        supervisory authority. You can contact us at any time at the address given
        in the legal notice for this and other questions on the subject of data
        protection.
      </p>

      <h4>Installation of the App PxtTypeplate</h4>

      <p>
        The PxtTypeplate app is available via distribution platforms operated by
        third parties, so-called app stores (Google Play and Apple App Store). Your
        download may require prior registration at the respective app store and
        installation of the app store software. We have no influence on the
        collection, processing and use of personal data in connection with your
        registration and the provision of downloads in the respective app store and
        the app store software. The responsible party in this respect is solely the
        operator of the respective app store. If necessary, please obtain
        information directly from the respective app store provider.
      </p>

      <h4>Required permissions</h4>

      <p>
        In order for the app to function properly, it is necessary that you grant
        access to certain smartphone functions that are stored on the device. Thus,
        you will be prompted once at the beginning or only when using the respective
        function to grant the corresponding access authorization.
      </p>

      <ol>
        <li>
          Network access &amp; network connections (to query data from the web)
        </li>
        <li>Camera access (to capture a QR code)</li>
        <li>Storage access (for local storage of already retrieved device data)</li>
      </ol>

      <h4>What data is required to use the service?</h4>

      <p>
        The PxtTypeplate app does not collect or store any personal data. To use the
        service, however, it is necessary that you transfer the serial number and
        type designation of your device to our web server via the available search
        mechanisms. Without providing this data, you will not be able to use the
        service.
      </p>

      <h4>
        Does Michael Koch GmbH or third parties receive data and for what purpose?
      </h4>

      <p>
        Only the transmitted device data is transferred to our server to trigger a
        database query there. The data is not stored and not transmitted to third
        parties.
      </p>

      <h4>Storage periods and deletion of the data used</h4>

      <p>
        When the app is actively used, the device data retrieved once from the web
        server is stored until it is uninstalled or until the app's memory area is
        emptied. Only the data collected automatically in the server log files is
        temporarily stored on the web server (see "Server log files").
      </p>

      <h4>External host</h4>

      <p>
        This website is hosted by an external service provider (Host). The personal
        data collected on this website is stored on the host's servers. This may
        include, but is not limited to, IP addresses, contact requests, meta and
        communication data, contract data, contact details, names, website accesses
        and other data generated by a website. The use of the host is for the
        purpose of fulfilling the contract with our potential and existing customers
        (Art. 6 para. 1 lit. b DSGVO) and in the interest of a secure, fast and
        efficient provision of our online offer by a professional provider (Art. 6
        para. 1 lit. f DSGVO). Our host will only process your data to the extent
        necessary to fulfill its performance obligations and will follow our
        instructions with regard to this data.
      </p>

      <h4>Signing of a contract for order processing</h4>

      <p>
        In order to guarantee data protection compliant processing, we have
        concluded a contract for order processing with our host.
      </p>

      <h4>General notes and mandatory information</h4>

      <h4>Pivacy protection</h4>

      <p>
        The operators of this app&nbsp;take the protection of your personal data
        very seriously. We treat your personal data confidentially and according to
        the legal data protection regulations as well as this privacy policy. When
        you use this website, various personal data is collected. Personal data is
        data with which you can be personally identified. This privacy policy
        explains what data we collect and what we use it for. It also explains how
        we do this and for what purpose. We would like to point out that data
        transmission over the Internet (e.g. communication by e-mail) can have
        security gaps. It is not possible to completely protect data from access by
        third parties.
      </p>

      <h4>Responsible party</h4>

      <p>The person responsible for data processing on this website is:</p>

      <p>
        Michael Koch GmbH<br />
        Zum Grenzgraben 28, Germany<br />
        76698 Ubstadt-Weiher<br />
        Phone: +49 72 51-96 26-200<br />
        E-Mail: <a href="mailto:mail@bremsenergie.de">mail@bremsenergie.de</a><br />
        The controller is the natural or legal person who, alone or jointly with
        others, determines the purposes and means of the processing of personal data
        (see paragraph Data Protection).
      </p>

      <h4>Data protection officer required by law</h4>

      <p>
        We have appointed a data protection officer:<br />
        Mr. Christian Wolff<br />
        Michael Koch GmbH<br />
        Zum Grenzgraben 28<br />
        76698 Ubstadt-Weiher<br />
        Tel: +49 72 51-96 26-200
      </p>

      <h4>Revocation of your consent to data processing</h4>

      <p>
        Many data processing operations are only possible with your express consent.
        You can revoke a previously given consent at any time. The legality of the
        data processing that took place up to the revocation remains unaffected by
        the revocation.
      </p>

      <h4>
        Right to object to data collection in special cases and to direct
        advertising (Art. 21 DSGVO)
      </h4>

      <p>
        If data processing is carried out on the basis of Art. 6 para. 1 lit. E or F
        DPA, you have the right to object to the processing of your personal data at
        any time for reasons arising from your particular situation. This also
        applies to profiling based on these provisions. The respective legal basis
        on which processing is based can be found in this data protection
        declaration. If you object, we will no longer process your personal data
        concerned unless we can prove compelling reasons for processing worthy of
        protection that outweigh your interests, rights and freedoms or the
        processing serves to assert, exercise or defend legal claims (objection in
        accordance with Art. 21 para. 1 DSGVO). If your personal data are processed
        for the purpose of direct advertising, you have the right to object at any
        time to the processing of personal data concerning you for the purpose of
        such advertising. This also applies to profiling, insofar as it is connected
        with such direct advertising. If you object, your personal data will no
        longer be used for the purpose of direct advertising afterwards (objection
        according to Art. 21 para. 2 DSGVO).
      </p>

      <h4>Right of appeal to the competent supervisory authority</h4>

      <p>
        In the case of violations of the DPAs, the persons concerned have a right of
        appeal to a supervisory authority, in particular in the Member State of
        their habitual residence, place of work or place of the suspected violation.
        This right of appeal is without prejudice to other administrative or
        judicial remedies.
      </p>

      <h4>Right to data transferability</h4>

      <p>
        You have the right to have data, which we process automatically on the basis
        of your consent or in fulfilment of a contract, handed over to you or to a
        third party in a common, machine-readable format. If you request the direct
        transfer of the data to another responsible party, this will only be done to
        the extent technically feasible.
      </p>

      <h4>SSL or TLS encryption</h4>

      <p>
        This web server uses SSL or TLS encryption for security reasons and to
        protect the transmission of confidential content, such as orders or
        inquiries that you send to us as site operator. You can recognize an
        encrypted connection by the fact that the address line of your browser
        changes from "http://" to "https://" and by the lock symbol in your browser
        line. If the SSL or TLS encryption is activated, the data you transmit to us
        cannot be read by third parties.
      </p>

      <h4>Right to limit processing</h4>

      <p>
        You have the right to request that the processing of your personal data be
        restricted. To do so, you can contact us at any time at the address given in
        the legal notice. The right to restrict processing exists in the following
        cases:<br />
        If you dispute the accuracy of your personal data stored with us, we usually
        need time to verify this. For the duration of the review, you have the right
        to demand the restriction of the processing of your personal data.<br />
        If the processing of your personal data was/is unlawful, you can demand the
        restriction of the data processing instead of deletion.<br />
        If we no longer need your personal data, but you need it for the exercise,
        defence or assertion of legal claims, you have the right to demand the
        restriction of the processing of your personal data instead of deletion.<br />
        If you have lodged an objection in accordance with Art. 21 paragraph 1
        DSGVO, a balance must be struck between your interests and ours. As long as
        it is not yet clear whose interests prevail, you have the right to demand
        the restriction of the processing of your personal data.<br />
        If you have restricted the processing of your personal data, this data -
        apart from its storage - may only be processed with your consent or for the
        assertion, exercise or defence of legal claims or for the protection of the
        rights of another natural or legal person or for reasons of an important
        public interest of the European Union or a member state.
      </p>

      <h4>How long will my data be stored?</h4>

      <p>
        We only store your data for as long as it is necessary for the processing of
        the business transaction or for compliance with documentation requirements.
        Legal requirements demand that commercial letters be kept for at least 6
        years, and those with tax-relevant content for at least 10 years. In
        addition, our IT systems have extensive data backup concepts that do not
        permit premature deletion. In this case, access to data is restricted after
        the permitted storage period has expired. Application documents are stored
        or retained for 6 months after the end of the filling of the position. If
        the application is made through a recruitment agency, master data must be
        retained for longer.
      </p>

      <h4>How secure is my data?</h4>

      <p>
        We use state-of-the-art security measures to protect your data
        appropriately. Our employees, subcontractors and trading partners are
        obliged to observe our guidelines on information security.
      </p>

      <h4>Server log files</h4>

      <p>
        The provider of the pages automatically collects and stores information in
        so-called server log files, which your browser automatically transmits to
        us. These are:<br />
        Browser type and browser version<br />
        Operating system used<br />
        Referrer URL<br />
        Hostname of the accessing computer<br />
        Time of the server request<br />
        IP address<br />
        This data is not merged with other data sources. The collection of these
        data is based on Art. 6 para. 1 lit. f DSGVO. The website operator has a
        legitimate interest in the technically error-free presentation and
        optimization of his website - for this purpose the server log files must be
        recorded.
      </p>

      <h4>Inquiry by e-mail, phone or fax</h4>

      <p>
        If you contact us by e-mail, telephone or fax, your inquiry including all
        personal data (name, inquiry, etc.) will be stored and processed by us for
        the purpose of processing your request. We will not pass on this data
        without your consent. The processing of this data is based on Art. 6 para. 1
        lit. b DSGVO, if your inquiry is related to the fulfilment of a contract or
        is necessary for the implementation of pre-contractual measures. In all
        other cases, processing is based on our legitimate interest in the effective
        processing of the inquiries addressed to us (Art. 6 para. 1 lit. f DSGVO) or
        on your consent (Art. 6 para. 1 lit. a DSGVO), provided that the inquiry was
        made. The data sent to us by you via contact inquiries will remain with us
        until you request us to delete it, revoke your consent to its storage or the
        purpose for which it was stored no longer applies (e.g. after your request
        has been processed). Mandatory legal provisions - in particular legal
        retention periods - remain unaffected.
      </p>

      <h4>Contact form</h4>

      <p>
        If you send us inquiries via the contact form, your details from the inquiry
        form, including the contact data you provide there, will be stored by us for
        the purpose of processing the inquiry and in the event of follow-up
        questions. We will not pass on this data without your consent. The
        processing of this data is based on Art. 6 para. 1 lit. b DSGVO, if your
        inquiry is related to the fulfillment of a contract or is necessary for the
        implementation of pre-contractual measures. In all other cases, processing
        is based on our legitimate interest in the effective processing of the
        inquiries addressed to us (Art. 6 para. 1 lit. f DSGVO) or on your consent
        (Art. 6 para. 1 lit. a DSGVO), provided that the inquiry was made. The data
        entered by you in the contact form will remain with us until you request us
        to delete it, revoke your consent to its storage or the purpose for which it
        was stored ceases to apply (e.g. after your inquiry has been processed).
        Mandatory legal provisions - in particular retention periods - remain
        unaffected.
      </p>

      <h4>Legal basis</h4>

      <p>
        The storage of comments/messages is based on your consent (Art. 6 para. 1
        lit. a DSGVO). You can revoke any consent you have given at any time. All
        you need to do is send us an informal message by e-mail. The legality of the
        data processing operations already carried out remains unaffected by the
        revocation.
      </p>

      <h4>Objection advertising mails</h4>

      <p>
        The use of contact data published within the scope of the legal notice
        obligation for the transmission of not expressly requested advertisement and
        information materials is hereby contradicted. The operators of the site
        expressly reserve the right to take legal action in the event of unsolicited
        sending of advertising information, such as through spam e-mails.<strong
          >&nbsp;</strong
        >
      </p>

      <h4>Right to revoke permission</h4>

      <p>
        Permission for the collection and storage of your data can be revoked at any
        time with respect to subsequent services. You can revoke permission for the
        collection, processing and use of your personal data at any time with
        respect to subsequent services. In this case, please contact us via the
        address specified in our imprint or via
        <a href="mailto:mail@bremsenergie.de.">mail@bremsenergie.de.</a> Personal
        data is deleted if you revoke permission for its storage, if your personal
        data is no longer required for the purpose for which it was originally
        stored, or if storage is no longer permissible for other legal reasons. This
        does not affect data required for billing and accounting purposes, or that
        has to be stored for legal reasons.
      </p>

      <h4>Safety</h4>

      <p>
        KOCH implements technical and organizational security measures to protect
        your personal data against unintentional or unlawful deletion, alteration or
        loss as well as unauthorized disclosure or access.
      </p>

      <h4>Contact</h4>

      <p>
        For information, suggestions and complaints regarding the processing of your
        personal data, please refer to the contact address:
        <a
          href="mailto:mail@bremsenergie.de"
          rel="noopener noreferrer"
          target="_blank"
          >mail@bremsenergie.de</a
        >. Should incorrect information be stored, despite our efforts to ensure
        that the data stored is accurate, we will correct this information at your
        request.
      </p>

      <h4>Adaptation of the privacy policy</h4>

      <p>
        Please note that if necessary we can or must make adjustments to this
        information in accordance with Art. 13/14 DSGVO for data processing. The
        current version of this information according to Art 13/14 DSGVO can be
        found on our homepage at any time.
      </p>
    </div>
  </template>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n()
</script>

<style scoped lang="scss">
  .ppWrapper {
    margin: 5rem
  }

  h4 {
    margin-bottom: 0.25rem;
  }

  p {
    margin-bottom: 0.5rem
  }
</style>
