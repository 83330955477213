import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-3 font-weight-bold" }
const _hoisted_2 = { class: "inputElements__item__content__infoText" }
const _hoisted_3 = { class: "inputElements__item__content__infoText" }
const _hoisted_4 = {
  key: 1,
  class: "inputElements__item__content__infoText"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "inputElements__item__content__infoText" }
const _hoisted_7 = { class: "inputElements__item__content__infoText" }
const _hoisted_8 = { class: "inputElements__item__content__infoText" }
const _hoisted_9 = { class: "inputElements__item__content__inputs" }
const _hoisted_10 = {
  key: 0,
  class: "inputElements__item__content__infoText"
}
const _hoisted_11 = { class: "inputElements__item__content__infoText" }
const _hoisted_12 = { class: "inputElements__item__content__buttons" }

import countries from 'i18n-iso-countries'
import * as enCountries from 'i18n-iso-countries/langs/en.json'
import * as deCountries from 'i18n-iso-countries/langs/de.json'
import { inputParameterNames, contactFormStructure, cycleDataInputProfileType } from 'koch-types/src/pxtDesignPortalFrontend'
import { enMessage } from '../../../locales/pxtDesignPortalLocales'
import { reactive, computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
// @ts-ignore
import { useMq } from 'vue3-mq'
import sliderNumberInputTuple from './components/sliderNumberInputTuple.vue'

// Head to inputAndSolutionsElement.vue in order to have an explanation on the following 4 objects

export default /*@__PURE__*/_defineComponent({
  __name: 'inputElement',
  setup(__props) {

_useCssVars(_ctx => ({
  "b104d358": (responsiveCSS.value.vWindowContentWidth),
  "3e77b91f": (responsiveCSS.value.vWindowContentPadding),
  "1830b1fc": (responsiveCSS.value.textFontSize)
}))

const store = useStore()
const { t, locale } = useI18n()
const mq = useMq()

watch(store.state.slideIndex, async (currVal: { value: number }) => {
  // console.log(`currVal.value: ${currVal.value}`)
  // console.log('store.state')
  // console.log(store.state)
  const proceedAllowed = (currVal.value === 0) ||
                        (currVal.value === 1 && store.state.useCase !== undefined) ||
                        (currVal.value === 2 && store.state.effectiveMainsVoltage !== undefined) ||
                        (currVal.value === 3 && store.state.useCase !== undefined && store.state.effectiveMainsVoltage !== undefined) ||
                        (currVal.value > 3)
  proceedDisabled.value = !proceedAllowed
  // console.log(`proceedDisabled.value: ${proceedDisabled.value}`)

  if (currVal.value === 7) {
    proceedDisabled.value = checkEnoughInformation(contactFormContent)
  }

  if (store.state.inputValues) {
    contactFormContent.designData.inputs = Object.entries(store.state.inputValues).map(([key, value]) => {
      return key + ': ' + ((store.state.useCase === 'DES' && key.startsWith('P')) ? ((value as number) * -1) : value) + (key.startsWith('p') ? ' kW' : ' s')
    }).join(', ')
  }

  if (store.state.effectiveMainsVoltage) {
    contactFormContent.designData.effectiveMainsVoltage = store.state.effectiveMainsVoltage + ' ' + suffixes.effectiveMainsVoltage
  }

  if (store.state.solutions) {
    contactFormContent.designData.solution = store.state.solutions.map((solutionElement: any) => {
      return solutionElement.amount + 'x ' + getDeviceDisplayName(solutionElement.type) + (solutionElement.specifications.amountOfIntegratedCaps ? ' mit ' + solutionElement.specifications.amountOfIntegratedCaps.value + ' Speicher' : '') + ((solutionElement.type === 'PxtMX' && systemConfiguration.value.communicationAPI === true) ? ' mit Kommunikationsschnittstelle' : '')
    }).join(', ')

    if (systemConfiguration.value.completeSystem === true) {
      contactFormContent.designData.solution = contactFormContent.designData.solution + ' | Die Gesamtlösung als Komplettsystem im einsatzbereiten Schaltschrank'
    }
  } else {
    contactFormContent.designData.solution = t('solutionVisualizationElement.noSolution')
  }
})

function getDeviceDisplayName (deviceType: string) {
  let displayName
  if (deviceType === 'accessories') {
    displayName = t('solutionVisualizationElement.' + deviceType)
  }

  switch (deviceType) {
    case 'DLC 90V/10F':
      displayName = 'DSKM-090010-FCF'
      break
    case 'DLC 45V/165F':
      displayName = 'DSKM-045165-FCF'
      break
    case 'PxtEx':
      displayName = 'PxtEx'
      break
    case 'PxtRx':
      displayName = 'PxtRx'
      break
    case 'PxtFx':
      displayName = 'PxtFx'
      break
    case 'PxtMx':
      displayName = 'PxtMx'
      break
  }

  return displayName
}

const systemConfiguration = ref({
  completeSystem: false,
  communicationAPI: false
})

const suffixes: any = {
  powerOfFrequencyInverter: 'kW',
  dcLinkCapacity: 'µF',
  brakeChopperActivationThreshold: 'V',
  powerOfMotor: 'kW',
  ccdepth: 'mm',
  effectiveMainsVoltage: 'V',
  effectivePhaseCurrent: 'A'
}

const responsiveCSS = computed((): { [cssDescriptor: string]: string } => {
  const responsiveMultiplier = 0.85
  const vWindowItemBaseWidth = 35
  const vWindowContentBaseWidth = 21
  const vWindowItemContentPadYAxis = 1
  const vWindowItemContentPadXAxis = 2

  if (mq.smMinus) {
    return {
      vWindowItemWidth: (vWindowItemBaseWidth * responsiveMultiplier) + 'rem',
      vWindowContentWidth: (vWindowContentBaseWidth * responsiveMultiplier) + 'rem',
      vWindowContentPadding: '1rem 1rem 1rem 1rem',
      textFontSize: '0.8rem'
    }
  } else {
    return {
      vWindowItemWidth: vWindowItemBaseWidth + '20rem',
      vWindowContentWidth: vWindowContentBaseWidth + 'rem',
      vWindowContentPadding: vWindowItemContentPadYAxis + 'rem ' + vWindowItemContentPadXAxis + 'rem ' + vWindowItemContentPadYAxis + 'rem ' + vWindowItemContentPadXAxis + 'rem',
      textFontSize: '0.8rem'
    }
  }
})

// Local, component specific object in order to control the availability of use case options at the app start.
// This will be obsolete and can be moved to store once all modes are available. ATM the solutions for the modes
// are being calculated and added to backend mongodb database.
const useCases = ['DES', 'DEV', 'LSR']

const effectiveMainsVoltages = [
  {
    id: '230 V',
    value: 230
  },
  {
    id: '380 - 480 V',
    value: 400
  }
]

const gridInformation = ['effectiveMainsVoltage', 'effectivePhaseCurrent', 'numberOfElectricalPhases']

countries.registerLocale(enCountries)
countries.registerLocale(deCountries)

const projectTypes = [t('inquiryElement.values.singleProject'), t('inquiryElement.values.seriesProject')]

// selected element in select
const selected = ref({
  country: '',
  projectType: ''
})

// helper bool to control the enabled/disabled state of the <v-window>'s next slide arrow, based on wheter all
// form inputs on one slide have been inserted or not.
const proceedDisabled = ref(false)

// helper bool to control enabled/disabled state of the control cabinet depth input field, based on whether the user
// selected the solution as a ready-to-use control cabinet or not.

const controlCabinet = ref(false)

// local component storage object for the user's form inputs.
const contactFormContent: contactFormStructure = {
  designData: {
    useCase: undefined,
    effectiveMainsVoltage: undefined,
    effectivePhaseCurrent: undefined,
    numberOfElectricalPhases: undefined,
    inputs: undefined,
    solution: undefined
  },
  additionalApplicationData: {
    typeOfUseCase: '-',
    projectType: '-',
    frequencyInverterManufacturer: '-',
    articleNumberOfFrequencyInverter: '-',
    powerOfFrequencyInverter: '-',
    dcLinkCapacity: '-',
    brakeChopperActivationThreshold: '-',
    powerOfMotor: '-',
    ccdepth: '-',
    message: '-'
  },
  inquirerData: {
    name: undefined,
    phone: undefined,
    mail: undefined,
    company: undefined,
    department: undefined,
    street: undefined,
    zipCode: undefined,
    city: undefined,
    country: undefined
  }
}

// helper object in order to correctly render the sliders <v-window-item>
const cycleDataInputProfile: cycleDataInputProfileType = {
  p0: {
    inputTypeSign: 'P',
    inputTypeSpecifier: 'start',
    inputTypeDescription: 'pStart',
    unit: 'kW'
  },
  p1: {
    inputTypeSign: 'P',
    inputTypeSpecifier: 'end',
    inputTypeDescription: 'pEnd',
    unit: 'kW'
  },
  tP: {
    inputTypeSign: 't',
    inputTypeSpecifier: 'braking',
    inputTypeDescription: 'tBraking',
    unit: 's'
  },
  tZ: {
    inputTypeSign: 't',
    inputTypeSpecifier: 'cycle',
    inputTypeDescription: 'tCycle',
    unit: 's'
  }
}

const inputKeyMapping: Record<string, any> = {
  p0: {
    counterPart: 'p1',
    multiplier: 1,
    operation: '>'
  },
  p1: {
    counterPart: 'p0',
    multiplier: 1,
    operation: '<'
  },
  tZ: {
    counterPart: 'tP',
    multiplier: 0.5,
    operation: '>'
  },
  tP: {
    counterPart: 'tZ',
    multiplier: 2,
    operation: '<'
  }
}

const sliderValues = reactive({
  p0: 0,
  p1: 0,
  tP: 0,
  tZ: 0
})

const timeTicks = getTicks(0.1, 0.1, 1.13)
const powerTicks = getTicks(0.5, 0, 1.085)

// creates an url to the bremsenergie.de website, accessing and automatically filling a design portal specific
// form with the given information through inputs by the user. The user will be forwarded and only has to submit
// the form on the website.
function sendProductRequest () {
  /* const urlMutationBase : any = {} */
  // console.log(contactFormContent)
  // Die Website wurde umgebaut auf Wordpress, das funktioniert nicht mehr
  // const urlMutation = Object.entries(contactFormContent).map(([key, value]) => {
  //   switch (key) {
  //     case 'designData':
  //       key = 'field_auslegungsdaten'
  //       break
  //     case 'inquirerData':
  //       key = 'field_kontaktdaten'
  //       break
  //     case 'additionalApplicationData':
  //       key = 'field_optionale_anwendungsdaten'
  //       break
  //   }

  //   const content = Object.entries(value).map(([key, value]) => {
  //     return t('inquiryElement.labels.' + key) + ': ' + value
  //   }).join('\n')

  //   return 'edit[' + key + '][widget][0][value]=' + encodeURIComponent(content)
  // })

  // urlMutation.push('edit[field_mail][widget][0][value]=' + encodeURIComponent(contactFormContent.inquirerData.mail!))

  // const flattenedUrlMutation = urlMutation.join('&')

  // const sendFormURL = 'https://bremsenergie.de/' + locale.value + '/contact/design_tool_product_request?' + flattenedUrlMutation
  // window.open(sendFormURL)
  // <-------------
  const emailBody = `Hello Koch-Team,

this email was generated for ${contactFormContent.inquirerData.name || '-'}of the Company ${contactFormContent.inquirerData.company || '-'}by the Koch Design Portal.

Design Data:
  - Use Case: ${contactFormContent.designData.useCase || '-'}
  - Effective Mains Voltage: ${contactFormContent.designData.effectiveMainsVoltage || '-'}
  - Effective Phase Current: ${contactFormContent.designData.effectivePhaseCurrent || '-'}
  - Number of Electrical Phases: ${contactFormContent.designData.numberOfElectricalPhases || '-'}
  - Inputs: ${contactFormContent.designData.inputs || '-'}
  - Solution: ${contactFormContent.designData.solution || '-'}

Additional Application Data:
  - Type of Use Case: ${contactFormContent.additionalApplicationData.typeOfUseCase || '-'}
  - Project Type: ${contactFormContent.additionalApplicationData.projectType || '-'}
  - Frequency Inverter Manufacturer: ${contactFormContent.additionalApplicationData.frequencyInverterManufacturer || '-'}
  - Article Number of Frequency Inverter: ${contactFormContent.additionalApplicationData.articleNumberOfFrequencyInverter || '-'}
  - Power of Frequency Inverter: ${contactFormContent.additionalApplicationData.powerOfFrequencyInverter || '-'}
  - DC-Link Capacity: ${contactFormContent.additionalApplicationData.dcLinkCapacity || '-'}
  - Brake Chopper Activation Threshold: ${contactFormContent.additionalApplicationData.brakeChopperActivationThreshold || '-'}
  - Power of Motor: ${contactFormContent.additionalApplicationData.powerOfMotor || '-'}
  - CC-Depth: ${contactFormContent.additionalApplicationData.ccdepth || '-'}
  - Message: ${contactFormContent.additionalApplicationData.message || '-'}

Inquirer Data:
  - Name: ${contactFormContent.inquirerData.name || '-'}
  - Phone: ${contactFormContent.inquirerData.phone || '-'}
  - Email: ${contactFormContent.inquirerData.mail || '-'}
  - Company: ${contactFormContent.inquirerData.company || '-'}
  - Street: ${contactFormContent.inquirerData.street || '-'}
  - ZIP Code: ${contactFormContent.inquirerData.zipCode || '-'}
  - City: ${contactFormContent.inquirerData.city || '-'}
  - Country: ${contactFormContent.inquirerData.country || '-'}

  Regards,
  ${contactFormContent.inquirerData.name || '-'}
`

  const subject = `Product Request ${contactFormContent.inquirerData.name || ''}${contactFormContent.inquirerData.company || ''}`
  const mailtoLink = `mailto:mail@bremsenergie.de?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody)}`

  // Öffne das Mailprogramm des Clients
  window.open(mailtoLink, '_blank')
}

function getCountries (locale: any) {
  console.log(locale)
  console.log(countries.getNames(locale))
  locale = locale === 'de-DE' ? 'de' : 'en'
  if (Object.values(countries.getNames(locale)).length === 0) {
    return Object.values({ 'de-DE': 'Germany', 'en-EN': 'England', 'it-IT': 'Italy' })
  }
  return Object.values(countries.getNames(locale))
}

function checkEnoughInformation (info:contactFormStructure) {
  if (info.inquirerData?.name !== undefined &&
    info.inquirerData?.mail !== undefined &&
    info.inquirerData?.mail.includes('@') &&
    info.inquirerData?.mail.includes('.') &&
    info.inquirerData?.mail.length > 5 &&
    info.inquirerData?.company !== undefined) {
    return false
  }
  return true
}
// calculates the ticks of the slider values (slider values increase logarithmically? exponentially? haha Maths. dunno.)
// Thats why tick calculation is needed, cause sliders usually work linear.
function getTicks (stepSize: number, initialValue: number, multiplier: number) {
  let value = initialValue
  const ticks: number[] = [value]

  for (let i = 1; i < 50; i++) {
    value = Number(Number((value + (stepSize * Math.pow(multiplier, (i - 1)))).toFixed(1)).toFixed(1))
    ticks.push(value)
  }
  return ticks
}

function getClosestIndex (array: number[], value: number) {
  const diffArr = array.map(x => Math.abs((value) - x))
  const minNumber = Math.min(...diffArr)
  const index = diffArr.findIndex(x => x === minNumber)
  return index
}

// Sets the use case in the store object based on users selection (called from html)
async function setUseCaseAndContinue (event: Event) {
  // console.log('setUseCaseAndContinue')
  // console.log(cycleDataInputProfile)
  // console.log((event.target as HTMLInputElement).value)
  if ((event.target as HTMLInputElement).value === 'DEV' && cycleDataInputProfile.tZ) {
    setEffectiveMainsVoltageAndContinue(400, 1)

    delete cycleDataInputProfile.tZ
  } else if ((event.target as HTMLInputElement).value !== 'DEV' && !(cycleDataInputProfile.tZ)) {
    cycleDataInputProfile.tZ = {
      inputTypeSign: 't',
      inputTypeSpecifier: 'cycle',
      inputTypeDescription: 'tCycle',
      unit: 's'
    }
    store.commit('updateInputVals', { type: 'tZ', value: store.state.thresholds.tZ.minVal })
  }
  if (store.state.useCase !== (event.target as HTMLInputElement).value) {
    store.commit('changeUseCase', (event.target as HTMLInputElement).value)
  }
  store.commit('incrementSlideIndex')
  contactFormContent.designData.useCase = t('selectUseCaseElement.useCase.' + store.state.useCase)
}

// Sets the use case in the store object based on users selection (called from html)
async function setEffectiveMainsVoltageAndContinue (effectiveMainsVoltage: number, increaseSlideIndexBy = 1) {
  if (store.state.effectiveMainsVoltage !== effectiveMainsVoltage) {
    store.commit('changeEffectiveMainsVoltage', effectiveMainsVoltage)
  }
  store.commit('incrementSlideIndex', increaseSlideIndexBy)
}

async function setMaximumMainsPower (effectiveMainsVoltage: number, effectivePhaseCurrent: number, numberOfElectricalPhases: number) {
  // console.log(effectiveMainsVoltage, effectivePhaseCurrent, numberOfElectricalPhases)
  const maximumMainsPower: number = Math.round((((numberOfElectricalPhases === 3 ? (effectiveMainsVoltage / Math.sqrt(3)) : effectiveMainsVoltage) * effectivePhaseCurrent * numberOfElectricalPhases) / 1000) * 100) / 100
  if (store.state.effectiveMainsVoltage !== effectiveMainsVoltage) {
    store.commit('changeEffectiveMainsVoltage', effectiveMainsVoltage)
  }
  if (store.state.maximumMainsPower !== maximumMainsPower) {
    store.commit('setMaximumMainsPower', maximumMainsPower)
  }
}

function adaptSliderValues (value: number, type: string) {
  sliderValues[type as inputParameterNames] = value

  const validTicks = (type === 'p0' || type === 'p1') ? powerTicks : timeTicks

  if (type === 'p0' || type === 'tZ') {
    if (store.state.inputValues[inputKeyMapping[type].counterPart as inputParameterNames] > (inputKeyMapping[type].multiplier * store.state.inputValues[type])) {
      sliderValues[inputKeyMapping[type].counterPart as inputParameterNames] = getClosestIndex(validTicks, (inputKeyMapping[type].multiplier * store.state.inputValues[type]))
    }
  } else {
    if (store.state.inputValues[inputKeyMapping[type].counterPart as inputParameterNames] < (inputKeyMapping[type].multiplier * store.state.inputValues[type])) {
      if (type === 'tP') {
        if ((store.state.thresholds[inputKeyMapping[type].counterPart].maxVal) < inputKeyMapping[type].multiplier * store.state.inputValues[type]) {
          sliderValues[type as inputParameterNames] = getClosestIndex(validTicks, 0.5 * store.state.thresholds[inputKeyMapping[type].counterPart].maxVal)
        }
        sliderValues[inputKeyMapping[type].counterPart as inputParameterNames] = getClosestIndex(validTicks, (inputKeyMapping[type].multiplier * store.state.inputValues[type]))
      } else {
        sliderValues[inputKeyMapping[type].counterPart as inputParameterNames] = inputKeyMapping[type].multiplier * value
      }
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createBlock(_component_v_window, {
    "show-arrows": true,
    class: "inputElements",
    modelValue: _unref(store).state.slideIndex.value,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(store).state.slideIndex.value) = $event)),
    touch: false
  }, {
    next: _withCtx(({ props }) => [
      _createVNode(_component_v_btn, {
        icon: "mdi-chevron-right",
        disabled: proceedDisabled.value,
        onClick: () => {
          props.onClick()
        }
      }, null, 8, ["disabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_v_window_item, { class: "inputElements__item" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            elevation: "3",
            class: "inputElements__item__content"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(t)('welcomeElement.welcomePhrase')), 1),
              _createElementVNode("div", null, _toDisplayString(_unref(t)('welcomeElement.welcomeMessage')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_window_item, { class: "inputElements__item" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            elevation: "3",
            class: "inputElements__item__content"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('selectUseCaseElement.instruction')) + ": ", 1),
              _createVNode(_component_v_radio_group, { "hide-details": "" }, {
                default: _withCtx(() => [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(useCases, (item, i) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_v_radio, {
                          class: "inputElements__item__content__radio",
                          label: _unref(t)('selectUseCaseElement.useCase.' + item),
                          value: item,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (setUseCaseAndContinue($event)))
                        }, null, 8, ["label", "value"])
                      ]),
                      (i !== useCases.length - 1)
                        ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 64))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_window_item, { class: "inputElements__item" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            elevation: "3",
            class: "inputElements__item__content"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, _toDisplayString((_unref(store).state.useCase === 'DES' || _unref(store).state.useCase === 'DEV') ? _unref(t)('selectEffectiveMainsVoltage.instruction1') : _unref(t)('selectEffectiveMainsVoltage.instruction2')) + ": ", 1),
              (_unref(store).state.useCase === 'DES')
                ? (_openBlock(), _createBlock(_component_v_radio_group, {
                    key: 0,
                    "hide-details": ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(effectiveMainsVoltages, (item, i) => {
                        return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_v_radio, {
                              class: "inputElements__item__content__radio",
                              label: item.id,
                              value: item.id,
                              onClick: ($event: any) => (setEffectiveMainsVoltageAndContinue(item.value))
                            }, null, 8, ["label", "value", "onClick"])
                          ]),
                          (i !== useCases.length - 1)
                            ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 64))
                    ]),
                    _: 1
                  }))
                : (_unref(store).state.useCase === 'DEV')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_unref(t)('selectEffectiveMainsVoltage.instruction3')), 1))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, _renderList(gridInformation, (input) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: input }, [
                        (input !== 'numberOfElectricalPhases')
                          ? (_openBlock(), _createBlock(_component_v_text_field, {
                              key: 0,
                              label: _unref(t)('inquiryElement.labels.' + input),
                              density: "compact",
                              suffix: suffixes[input] ? suffixes[input] : undefined,
                              "hide-details": "",
                              onInput: (event) => {
                  // Sets the content of the contactFormContent object to the value input into this <v-text-field>.
                  // @ts-ignore
                  contactFormContent.designData[input] = (event.target as HTMLInputElement).value + ' ' + (suffixes[input] ? suffixes[input] : '')
                  // When every value of the current form part is neither undefined nor null, the proceedCheckBool will be adapted in order to manage
                  // the accessability of the <v-window>'s Window controls.'
                  // console.log('@input ', input)
                  // console.log(contactFormContent.designData)
                  if(contactFormContent.designData.effectivePhaseCurrent && contactFormContent.designData.effectiveMainsVoltage && contactFormContent.designData.numberOfElectricalPhases ) {
                    setMaximumMainsPower(parseInt(contactFormContent.designData.effectiveMainsVoltage.split(' ')[0]), parseInt(contactFormContent.designData.effectivePhaseCurrent.split(' ')[0]), parseInt(contactFormContent.designData.numberOfElectricalPhases))
                    proceedDisabled.value = false
                  } else {
                    proceedDisabled.value = true
                  }
                }
                            }, {
                              default: _withCtx(() => [
                                ((_unref(enMessage) as unknown as any).inquiryElement.tooltip[input])
                                  ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                      key: 0,
                                      activator: "parent",
                                      location: "end"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(t)('inquiryElement.tooltip.' + input)), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["label", "suffix", "onInput"]))
                          : (_openBlock(), _createBlock(_component_v_select, {
                              key: 1,
                              label: _unref(t)('inquiryElement.labels.' + input),
                              "onUpdate:modelValue": (event) => {
                    // console.log('@select ', input)
                    // console.log(contactFormContent.designData)
                    contactFormContent.designData[input] = event
                    if(contactFormContent.designData.effectivePhaseCurrent && contactFormContent.designData.effectiveMainsVoltage && contactFormContent.designData.numberOfElectricalPhases ) {
                      setMaximumMainsPower(parseInt(contactFormContent.designData.effectiveMainsVoltage.split(' ')[0]), parseInt(contactFormContent.designData.effectivePhaseCurrent.split(' ')[0]), parseInt(contactFormContent.designData.numberOfElectricalPhases))
                      proceedDisabled.value = false
                    } else {
                      proceedDisabled.value = true
                    }
                  },
                              items: [1,3],
                              required: "",
                              density: "compact",
                              "hide-details": ""
                            }, null, 8, ["label", "onUpdate:modelValue"]))
                      ], 64))
                    }), 64))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_window_item, { class: "inputElements__item" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, {
            elevation: "3",
            class: "inputElements__item__content"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(cycleDataInputProfile, (input, key, i) => {
                return _createVNode(sliderNumberInputTuple, {
                  key: i,
                  index: i,
                  inputKey: key,
                  profile: input,
                  sliderValue: sliderValues[key],
                  inputKeyMapping: inputKeyMapping,
                  ticks: (key === 'p0' || key === 'p1') ? _unref(powerTicks) : _unref(timeTicks),
                  "onUpdate:sliderValue": ($event: any) => (adaptSliderValues($event, key))
                }, null, 8, ["index", "inputKey", "profile", "sliderValue", "ticks", "onUpdate:sliderValue"])
              }), 64)),
              (_unref(store).state.useCase==='DEV')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(t)('selectEffectiveMainsVoltage.instruction3')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_unref(store).state.solutions !== undefined && (_unref(store).state.solutions.length > 0 || _unref(store).state.solutions === false))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_v_window_item, { class: "inputElements__item" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  elevation: "3",
                  class: "inputElements__item__content"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(t)('inquiryElement.instructions.productRequest')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_window_item, { class: "inputElements__item" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  elevation: "3",
                  class: "inputElements__item__content"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)('inquiryElement.instructions.systemConfig')), 1),
                    _createVNode(_component_v_checkbox, {
                      class: "inputElements__item__content__radio",
                      label: _unref(t)('inquiryElement.labels.controlCabinet'),
                      onChange: _cache[1] || (_cache[1] = (event) => {
                systemConfiguration.value.completeSystem = (event.target as HTMLInputElement).checked
              }),
                      "hide-details": ""
                    }, null, 8, ["label"]),
                    (_unref(store).state.useCase === 'LSR')
                      ? (_openBlock(), _createBlock(_component_v_checkbox, {
                          key: 0,
                          class: "inputElements__item__content__radio",
                          label: _unref(t)('inquiryElement.labels.communicationAPI'),
                          onChange: _cache[2] || (_cache[2] = (event) => {
                systemConfiguration.value.communicationAPI = (event.target as HTMLInputElement).checked
              }),
                          "hide-details": ""
                        }, null, 8, ["label"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(contactFormContent, (value, key, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (index !== 0)
                  ? (_openBlock(), _createBlock(_component_v_window_item, {
                      key: 0,
                      class: "inputElements__item"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_card, {
                          elevation: "3",
                          class: "inputElements__item__content"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('inquiryElement.instructions.' + (index === 1 ? 'applicationData' : 'inquirerData' ))), 1),
                            _createElementVNode("div", _hoisted_9, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (subValue, subKey, subIndex) => {
                                return (_openBlock(), _createElementBlock(_Fragment, { key: subIndex }, [
                                  (subKey !== 'message' && subKey !== 'country' && subKey !== 'projectType')
                                    ? (_openBlock(), _createBlock(_component_v_text_field, {
                                        key: 0,
                                        label: _unref(t)('inquiryElement.labels.' + subKey),
                                        disabled: controlCabinet.value === true && subKey === 'ccdepth',
                                        density: "compact",
                                        suffix: suffixes[subKey] ? suffixes[subKey] : undefined,
                                        "hide-details": "",
                                        onInput: (event) => {
                    // Sets the content of the contactFormContent object to the value input into this <v-text-field>.
                    // @ts-ignore
                    contactFormContent[key][subKey] = (event.target as HTMLInputElement).value + ' ' + (suffixes[subKey] ? suffixes[subKey] : '')
                    // When every value of the current form part is neither undefined nor null, the proceedCheckBool will be adapted in order to manage
                    // the accessability of the <v-window>'s slide controls.'
                    proceedDisabled.value = checkEnoughInformation(contactFormContent)
                  }
                                      }, {
                                        default: _withCtx(() => [
                                          (_unref(enMessage).inquiryElement.tooltip[subKey])
                                            ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                key: 0,
                                                activator: "parent",
                                                location: "end"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_unref(t)('inquiryElement.tooltip.' + subKey)), 1)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _: 2
                                      }, 1032, ["label", "disabled", "suffix", "onInput"]))
                                    : (subKey === 'country' || subKey === 'projectType')
                                      ? (_openBlock(), _createBlock(_component_v_select, {
                                          key: 1,
                                          "model-value": selected.value[subKey],
                                          label: _unref(t)('inquiryElement.labels.' + subKey),
                                          "onUpdate:modelValue": (event) => {
                    // @ts-ignore
                    contactFormContent[key][subKey] = event
                    selected.value[subKey] = event
                    proceedDisabled.value = checkEnoughInformation(contactFormContent)
                  },
                                          items: subKey === 'country' ? getCountries(_unref(locale)) : projectTypes,
                                          required: "",
                                          density: "compact",
                                          "hide-details": ""
                                        }, null, 8, ["model-value", "label", "onUpdate:modelValue", "items"]))
                                      : (_openBlock(), _createBlock(_component_v_textarea, {
                                          key: 2,
                                          density: "compact",
                                          "hide-details": "",
                                          label: _unref(t)('inquiryElement.labels.' + subKey),
                                          onInput: (event) => {
                  contactFormContent.additionalApplicationData.message = (event.target as HTMLInputElement).value
                }
                                        }, null, 8, ["label", "onInput"]))
                                ], 64))
                              }), 128))
                            ]),
                            (key === 'inquirerData')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(t)('inquiryElement.instructions.requiredArguments')), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true)
              ], 64))
            }), 64)),
            _createVNode(_component_v_window_item, { class: "inputElements__item" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, {
                  elevation: "3",
                  class: "inputElements__item__content"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(t)('inquiryElement.instructions.forwardMessage')), 1),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_v_btn, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (sendProductRequest()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('sendInquiryElement.proceed')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})