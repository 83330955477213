<template>
  <v-hover v-slot="{ isHovering, props }">
    <div class="amountIllustrationTuple"
      v-bind="props"
    >
    <v-fade-transition>
      <div
        v-if="isHovering && deviceType.startsWith('Pxt')"
        class="amountIllustrationTuple__hoverElement"
      >
        <v-btn
          icon
          @click="forwardToProductPage(deviceType, locale)"
        >
          <v-tooltip
            activator="parent"
            location="bottom"
          >
            {{t('solutionVisualizationElement.tooltip.productPage')}}
          </v-tooltip>
          <v-icon>
            mdi-arrow-right
          </v-icon>
        </v-btn>
      </div>
    </v-fade-transition>
      <div class="amountIllustrationTuple__amount text-h5">
        {{amount}}x
      </div>
      <div class="amountIllustrationTuple__illustration">
        <img
          class="amountIllustrationTuple__illustration__deviceImage"
          :src="getDeviceIllustrationImgUrl(deviceType)"
        />
        <img v-if="!deviceType.startsWith('DLC') && deviceType !== 'accessories'"
          class="amountIllustrationTuple__illustration__deviceName--v-img--md+"
          :src="getDeviceNameImgUrl(deviceType)"
        />
        <div v-else
          class="amountIllustrationTuple__illustration__deviceName--text--md+ text-body text-center"
        >
          {{getDeviceDisplayName(deviceType) === 'accessories' ? t('solutionVisualizationElement.' + getDeviceDisplayName(deviceType)) : getDeviceDisplayName(deviceType)}}
        </div>
      </div>
    </div>
  </v-hover>
</template>

<script setup lang="ts">

// @ts-ignore
import { useMq } from 'vue3-mq'
import { useI18n } from 'vue-i18n'
import { computed, toRefs, defineProps } from 'vue'
import { deviceSpecifications } from 'koch-types/src/pxtDesignPortalFrontend'

// helpers
// For a detailed explanation of the following 2 Objects, head to the component inputAndSolutionElement.vue
const mq = useMq()
const { t, locale } = useI18n()

// defines properties specific to this component
const props = defineProps<{
  deviceType: string;
  amount: number;
  specifications: deviceSpecifications
}>()

// makes properties programmatically mutable (its a vue specific thing, watch inside inputAndSolutionsElement.vue for more info)
const { deviceType, amount, specifications } = toRefs(props)

function getDeviceNameImgUrl (deviceType : string) {
  return require(`@/assets/products/logos/${deviceType}_Logo.png`)
}

function forwardToProductPage (deviceType : string, locale : string) {
  const linkToProduct = 'https://bremsenergie.de/' + locale + '/' + deviceType
  window.open(linkToProduct)
}

function getDeviceDisplayName (deviceType : string) {
  let displayName
  if (deviceType === 'accessories') {
    displayName = t('solutionVisualizationElement.' + deviceType)
  }
  switch (deviceType) {
    case 'DLC 90V/10F':
      displayName = 'DSKM-090010-FCF'
      break
    case 'DLC 45V/165F':
      displayName = 'DSKM-045165-FCF'
      break
    case 'PxtEx':
      displayName = 'PxtEx'
      break
    case 'PxtRx':
      displayName = 'PxtRx'
      break
    case 'PxtFx':
      displayName = 'PxtFx'
      break
  }

  return displayName
}

function getDeviceIllustrationImgUrl (deviceType : string) {
  const integratedCapsNumber = specifications.value.amountOfIntegratedCaps ? parseInt(specifications.value.amountOfIntegratedCaps.value) : 0
  let deviceIllustrationImgName = 'unknown'
  switch (deviceType) {
    case 'DLC 90V/10F':
      deviceIllustrationImgName = 'DLC9010'
      break
    case 'DLC 90V/20F':
      deviceIllustrationImgName = 'DLC9020'
      break
    case 'DLC 45V/165F':
      deviceIllustrationImgName = 'DLC45165'
      break
    case 'DLC 45V/330F':
      deviceIllustrationImgName = 'DLC45330'
      break
    case 'PxtRx':
      deviceIllustrationImgName = deviceType
      break
    case 'PxtMx':
      deviceIllustrationImgName = deviceType
      break
    case 'accessories':
      deviceIllustrationImgName = deviceType
      break
    case 'PxtEx':
    case 'PxtFx':
      if (integratedCapsNumber) {
        if (integratedCapsNumber >= 1 && integratedCapsNumber <= 3) {
          deviceIllustrationImgName = deviceType + '_' + integratedCapsNumber
        } else if (integratedCapsNumber > 3) {
          deviceIllustrationImgName = deviceType + '_' + '3'
        } else {
          deviceIllustrationImgName = deviceType + '_' + '1'
        }
      } else {
        break
      }
  }
  return require(`@/assets/products/illustrations/${deviceIllustrationImgName}.png`)
}

// methods
// For a detailed explanation head to inputAndSolutionElement.vue
const responsiveCSS = computed(() : {[thingName : string] : string} => {
  if (mq.smMinus) {
    return {
      alignItems: 'center',
      flexDirection: 'row',
      marginRight: '0.5rem'
    }
  } else {
    return {
      alignItems: 'center',
      flexDirection: 'column',
      marginRight: '0'
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .amountIllustrationTuple {
      position: relative;
      height: 9.8125rem;
      display: flex;
      flex-direction: row;
      align-items: v-bind('responsiveCSS.alignItems');

      &__hoverElement {
        position: absolute;
        z-index: 1;
        background-color: white;
        background: rgba(255, 255, 255, 0.9);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        white-space: pre-line;
        text-align: center;

        .v-btn {
          opacity: 1
        }
      }

      &__amount {
        text-align: right;
      }

      &__illustration {
        position: relative;
        &__deviceImage {
          width: 9rem;
          height: 100%;
        }

        &__deviceName {
          &--v-img {
            &--md\2b {
              position: absolute;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              top: 85%;
              left: 50%;
              height: 22%;
              width: auto;
            }
            &--electronic {
              position: absolute;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              top: 85%;
              left: 50%;
              height: 22%;
              width: auto;
            }
            &--cap {
              width: 5rem
            }
          }
          &--text {
            &--md\2b {
              position: absolute;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
              top: 110%;
              left: 50%;
              height: 22%;
              width: 100%
            }
          }
        }
      }
    }
</style>
