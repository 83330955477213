<template>
  <div class="inputArea">
      <div class="inputArea__inlineWrapper">
        <div
          class="inputArea__inlineWrapper__description"
        >
          {{profile.inputTypeSign}}<sub>{{t("inputElement.depictor.index." + profile.inputTypeSpecifier)}}</sub>
          <sup>
            <v-icon
              size="x-small"
              color="grey darken-4"
            >
              mdi-help
            </v-icon>
          </sup>
          <v-tooltip
            activator="parent"
            location="top"
          >{{t("inputElement.tooltip." + profile.inputTypeDescription)}}
          </v-tooltip>
        </div>
        <v-text-field
          class="inputArea__inlineWrapper__textField text-caption"
          :placeholder="inputValueComputed.toString()"
          persistent-placeholder
          :modelValue="inputValueComputed"
          @update:modelValue ="(v: string) => {
            inputChangeOrigin = 'input';
            inputValueComputed = parseFloat(v.replace(',', '.'))
          }"
          density="compact"
          variant="underlined"
          :step="store.state.thresholds[props.inputKey].stepSize"
          :suffix="profile.unit"
          hide-details
        />
      </div>
      <v-slider
        class="inputArea__slider"
        :modelValue="sliderValue"
        @update:modelValue="(v: number) => {
          inputChangeOrigin = 'slider'
          inputValueComputed = v
        }"
        color="#E30018"
        track-color="lightgrey"
        :max="getClosestIndex(props.ticks, store.state.thresholds[props.inputKey].maxVal) + 1"
        :min="0"
        :step="0.1"
        thumb-size=15
        hide-details
      />
  </div>
</template>

<script setup lang="ts">

import { defineProps, computed, defineEmits, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { inputParameterNames, inputProfileType } from 'koch-types/src/pxtDesignPortalFrontend'
// @ts-ignore
import { useMq } from 'vue3-mq'

const emit = defineEmits(['update:sliderValue'])
const mq = useMq()

const props = defineProps<{
  index: number;
  profile: inputProfileType;
  inputKey : inputParameterNames;
  sliderValue: number;
  inputKeyMapping : any;
  ticks : number[]
}>()

// helpers
const { t } = useI18n()
const store = useStore()

const inputChangeOrigin = ref('')

watch(store.state.slideIndex, async (currVal : {value: number}) => {
  if (currVal.value === 2) {
    store.commit('resetInputVals')
    inputChangeOrigin.value = 'input'
    inputValueComputed.value = store.state.inputValues[props.inputKey]
  }
})

function getClosestIndex (array : number[], value : number) {
  const diffArr = array.map(x => Math.abs((value) - x))
  const minNumber = Math.min(...diffArr)
  const index = diffArr.findIndex(x => x === minNumber)
  return index
}

const responsiveCSS = computed(() : {[cssDescriptor : string] : string} => {
  if (mq.smMinus) {
    return {
      fontSize: '16px'
    }
  } else {
    return {
      fontSize: '16px'
    }
  }
})

const inputValueComputed = computed({
  get () : number {
    return store.state.inputValues[props.inputKey]
  },
  set (value : number) {
    let sliderValueTemp
    if ((value === null || value === undefined) || isNaN(value)) {
      value = store.state.thresholds[props.inputKey].minVal
    }
    if (inputChangeOrigin.value === 'slider') {
      sliderValueTemp = value
      if (value % 1 === 0) {
        value = props.ticks[value]
      } else {
        const lowerTickIndex = Number((value - Number((value % 1).toFixed(1))).toFixed(0))
        const upperTickIndex = Number((value + Number((1 - (value % 1)).toFixed(1))).toFixed(0))

        const lowerTickValue = props.ticks[lowerTickIndex]
        const upperTickValue = props.ticks[upperTickIndex]

        const delta = Number((upperTickValue - lowerTickValue).toFixed(1))
        value = Number((lowerTickValue + (delta * (value % 1))).toFixed(1))
      }
    } else {
      sliderValueTemp = getClosestIndex(props.ticks, value)
    }

    if (value < store.state.thresholds[props.inputKey].minVal) {
      value = store.state.thresholds[props.inputKey].minVal
    } else if (value > store.state.thresholds[props.inputKey].maxVal) {
      value = store.state.thresholds[props.inputKey].maxVal
    }

    store.commit('updateInputVals', { type: props.inputKey, value: value })

    // general adaption of slider thumb position and dedicated input value
    emit('update:sliderValue', sliderValueTemp)

    if (props.inputKey === 'p0' || props.inputKey === 'tZ') {
      if (store.state.inputValues[props.inputKeyMapping[props.inputKey].counterPart] > (props.inputKeyMapping[props.inputKey].multiplier * value)) {
        // adaption to the upper and lower borders/thresholds of possible input values
        if (store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].minVal > props.inputKeyMapping[props.inputKey].multiplier * value) {
          store.commit('updateInputVals', { type: props.inputKeyMapping[props.inputKey].counterPart, value: store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].minVal }) // adapts dedicated counter value to thresholds
          store.commit('updateInputVals', { type: props.inputKey, value: 2 * store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].minVal }) // adapts interaction Values to thresholds
        } else {
          store.commit('updateInputVals', { type: props.inputKeyMapping[props.inputKey].counterPart, value: (props.inputKeyMapping[props.inputKey].multiplier * value) }) // adapts dedicated counter value
        }
      }
    } else {
      if (store.state.inputValues[props.inputKeyMapping[props.inputKey].counterPart] < (props.inputKeyMapping[props.inputKey].multiplier * value)) {
        // adaption to the upper and lower borders/thresholds of possible input values
        if ((store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].maxVal) < props.inputKeyMapping[props.inputKey].multiplier * value) {
          store.commit('updateInputVals', { type: props.inputKeyMapping[props.inputKey].counterPart, value: store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].maxVal }) // adapts dedicated counter value to thresholds
          store.commit('updateInputVals', { type: props.inputKey, value: 0.5 * store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].maxVal }) // adapts interaction Values to thresholds
        } else {
          store.commit('updateInputVals', { type: props.inputKeyMapping[props.inputKey].counterPart, value: (props.inputKeyMapping[props.inputKey].multiplier * value) }) // adapts dedicated counter value
        }
      }
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .inputArea {
      font-size:  v-bind('responsiveCSS.fontSize');
      width: 100%;
      margin: 0;
      &__inlineWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: auto;

        &__textField {
          flex: 0 0 30%;
        }
        &__textField {
          font-size:  v-bind('responsiveCSS.fontSize');
        }
        &__textField ::v-deep(input) {
          width: 100%;
          text-align: right;
          margin-right: 0.2rem;
        }

        &__textField ::v-deep(input::-webkit-outer-spin-button) {
          -webkit-appearance: none;
        }

        &__textField ::v-deep(input::-webkit-inner-spin-button) {
          -webkit-appearance: none;
        }
      }
      &__slider {
        display: block;
        width: 100%;
        margin: auto;
      }
  }
</style>
