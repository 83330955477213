import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "font-weight-bold text-right" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "fixedy" }
const _hoisted_5 = { class: "font-weight-bold text-right" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "fixedy" }

import deviceAmountTuple from './components/deviceAmountTuple.vue'
import { enMessage } from '../../../locales/pxtDesignPortalLocales'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { deviceSpecificationParameters, deviceSpecifications, valueUnitTuple } from 'koch-types/src/pxtDesignPortalFrontend'
import { computed } from 'vue'
// @ts-ignore
import { useMq, MqResponsive } from 'vue3-mq'

// For a detailed explanation of the following 4 Objects, head to the component inputAndSolutionElement.vue

export default /*@__PURE__*/_defineComponent({
  __name: 'solutionVisualizationElement',
  setup(__props) {

_useCssVars(_ctx => ({
  "06f89e71": (responsiveCSS.value.vTableFontSize)
}))

const mq = useMq()
const { t } = useI18n()
const store = useStore()

const responsiveCSS = computed(() : {[cssDescriptor : string] : string} => {
  const responsiveMultiplier = 0.8
  const vTableBaseHeight = 7
  const vTableBaseFontSize = 1
  if (mq.smMinus) {
    return {
      vTableHeight: vTableBaseHeight + 'rem',
      vTableFontSize: (vTableBaseFontSize * responsiveMultiplier) + 'rem'
    }
  } else {
    return {
      vTableHeight: vTableBaseHeight + 'rem',
      vTableFontSize: (vTableBaseFontSize * responsiveMultiplier) + 'rem'
    }
  }
})


return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_window_item = _resolveComponent("v-window-item")!
  const _component_v_window = _resolveComponent("v-window")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(MqResponsive), { target: "md+" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              align: "center",
              justify: "space-evenly"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.solutions, (solution, i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                    _createVNode(_component_v_col, { cols: "auto" }, {
                      default: _withCtx(() => [
                        _createVNode(deviceAmountTuple, {
                          deviceType: solution.type,
                          amount: solution.amount,
                          specifications: solution.specifications!
                        }, null, 8, ["deviceType", "amount", "specifications"])
                      ]),
                      _: 2
                    }, 1024),
                    (_unref(store).state.solutions.length > 0 && i !== (_unref(store).state.solutions.length - 1))
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "auto"
                        }, {
                          default: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createElementVNode("div", { class: "text-h6 text-md-h5" }, " + ", -1)
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { justify: "space-between" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.solutions, (solution, i) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    key: i,
                    cols: 12/_unref(store).state.solutions.length
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_table, {
                        density: "compact",
                        "max-height": responsiveCSS.value.vTableHeight
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("tbody", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(solution.specifications as deviceSpecifications), (specification, index) => {
                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                _createElementVNode("td", _hoisted_1, [
                                  (specification[0].toLowerCase().includes('max'))
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        innerHTML: _unref(t)('solutionVisualizationElement.tableSpecs.' + specification[0])
                                      }, null, 8, _hoisted_2))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(t)('solutionVisualizationElement.tableSpecs.' + specification[0])), 1)),
                                  _cache[1] || (_cache[1] = _createTextVNode(": ")),
                                  ((_unref(enMessage) as unknown as any).solutionVisualizationElement.tooltip[specification[0]])
                                    ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                        key: 2,
                                        activator: "parent",
                                        location: "start"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_unref(t)("solutionVisualizationElement.tooltip." + specification[0])), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("td", {
                                  class: _normalizeClass({'flexy' : specification[0] !== 'content', 'nowrap' : specification[0] === 'dimensions'})
                                }, [
                                  _createElementVNode("div", null, _toDisplayString(specification[1].value === 'accessories' ? _unref(t)('solutionVisualizationElement.tableSpecs.accessories')  : specification[1].value), 1),
                                  _createElementVNode("div", _hoisted_4, _toDisplayString(specification[1].unit), 1)
                                ], 2)
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 2
                      }, 1032, ["max-height"])
                    ]),
                    _: 2
                  }, 1032, ["cols"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(MqResponsive), { target: "sm-" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_window, { "show-arrows": "" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.solutions, (solution, i) => {
              return (_openBlock(), _createBlock(_component_v_window_item, { key: i }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_container, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, {
                        align: "center",
                        justify: "center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "auto" }, {
                            default: _withCtx(() => [
                              _createVNode(deviceAmountTuple, {
                                deviceType: solution.type,
                                amount: solution.amount,
                                specifications: solution.specifications!
                              }, null, 8, ["deviceType", "amount", "specifications"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_v_row, { justify: "center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_col, { cols: "auto" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_table, {
                                density: "compact",
                                "max-height": responsiveCSS.value.vTableHeight
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(solution.specifications as deviceSpecifications), (specification, index) => {
                                      return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                        _createElementVNode("td", _hoisted_5, [
                                          (specification[0].toLowerCase().includes('max'))
                                            ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                innerHTML: _unref(t)('solutionVisualizationElement.tableSpecs.' + specification[0])
                                              }, null, 8, _hoisted_6))
                                            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(t)('solutionVisualizationElement.tableSpecs.' + specification[0])), 1)),
                                          _cache[2] || (_cache[2] = _createTextVNode(" : ")),
                                          ((_unref(enMessage) as unknown as any).solutionVisualizationElement.tooltip[specification[0]])
                                            ? (_openBlock(), _createBlock(_component_v_tooltip, {
                                                key: 2,
                                                activator: "parent",
                                                location: "start"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_unref(t)("solutionVisualizationElement.tooltip." + specification[0])), 1)
                                                ]),
                                                _: 2
                                              }, 1024))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _createElementVNode("td", {
                                          class: _normalizeClass({'flexy' : specification[0] !== 'content'})
                                        }, [
                                          _createElementVNode("div", null, _toDisplayString(specification[1].value === 'accessories'
                        ? _unref(t)('solutionVisualizationElement.tableSpecs.accessories')
                        : specification[1].value === 'maxEnergyInput' && _unref(store).state.effectiveMainsVoltage === 230
                          ? parseFloat(specification[1].value) * 0.85 // ACHTUNG!!!! DAS IST EIN WORKAROUND FÜR 230V-Netze, da hier andere Kondensatoren im EX verbaut werden, die eine andere Energie nach sich ziehen, Das sollte eigentlich über die Datenbank-Collection "deviceVariants" abgefangen werden, leider bestand dazu keine Zeit mehr.
                          : specification[1].value), 1),
                                          _createElementVNode("div", _hoisted_8, _toDisplayString(specification[1].unit), 1)
                                        ], 2)
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _: 2
                              }, 1032, ["max-height"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})