<template>
  <div
    class="ldWrapper"
  >
    <div class="legalDisclosure">
      <div
        v-for="(value, key, index1) in legalDisclosure"
        :key="index1"
        class="legalDisclosure__section"
      >
      <div class="legalDisclosure__section__header">
        {{t('legalDisclosure.headings.' + key)}}
      </div>
      <div
        v-if="typeof value === 'string'"
        class="legalDisclosure__section__content"
      >
        <div class="legalDisclosure__section__content__key">
          {{t('legalDisclosure.keys.' +
              value)}}
        </div>
      </div>
        <template
          v-else
        >
          <template
            v-for="(subValue, key, index2) in value"
            :key="index2"
          >
            <div
              v-if="subValue === 'content' || subValue === 'links' || subValue ==='copyright'"
              class="legalDisclosure__section__content--breakStructure"
            >
              <div
                class="legalDisclosure__section__content__key legalDisclosure__section__content__key--breakStructure"
              >
                {{t('legalDisclosure.keys.' + subValue)}}
              </div>
              <div
                class="legalDisclosure__section__content__value"
              >
                {{t('legalDisclosure.values.' + subValue)}}
              </div>
            </div>
            <div
              v-else
              class="legalDisclosure__section__content"
            >
              <div class="legalDisclosure__section__content__key">
                {{t('legalDisclosure.keys.' + key)}}
              </div>
              <div
                class="legalDisclosure__section__content__value"
              >
                {{subValue}}
              </div>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
const { t, locale } = useI18n()
const legalDisclosure = {
  owner: {
    company: 'Michael Koch GmbH',
    CEOs: 'Michael Koch, Christine Lieber-Koch',
    address: 'Zum Grenzgraben 28, 76698 Ubstadt-Weiher, Deutschland',
    telephone: '+49 7251 96 26-200',
    telefax: '+49 7251 96 26-210',
    Internet: 'www.bremsenergie.de',
    email: 'mail@bremsenergie.de',
    legalForm: 'GmbH',
    UStIdNr: 'DE 186 766 404',
    commercialRegister: 'HRB 231860 Registergericht Mannheim'
  },
  dataProtectionOfficer: 'dataProtectionOfficer',
  dataProtectionAuthority: 'dataProtectionAuthority',
  exclusionOfLiability: {
    content: 'content',
    links: 'links',
    copyright: 'copyright'
  }
}
</script>

<style scoped lang="scss">
  .ldWrapper {
    margin: 5rem
  }

  .legalDisclosure {
      margin: var(--displayMarginLeft);
      background-color: white;
      z-index: 4;
      overflow-x: hidden;
      overflow-y: auto;

      &__section {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid lightgrey;

        &__header {
          margin-bottom: 10px;
          font-weight: 600;
          font-size: large;
          text-align: left;
          width: 100%;
          -webkit-hyphens: auto;
          -ms-hyphens: auto;
          hyphens: auto;
        }

        &__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 5px;
          font-size: smaller;

          &--breakStrukture {
            margin-bottom: 5px;
            font-size: smaller;
          }

          &--breakStructure > .legalDisclosure__section__content__value {
            text-align: left;
            font-size: inherit;
          }

          &__key {
            text-align: left;

            &--breakStructure {
              text-align: left;
             font-weight: 600;
            }
          }

          &__value {
            text-align: right;
            flex: 0 0 40%;
          }
        }
      }

      &__section:first-child {
       border-top: 1px solid lightgrey;
      }
    }
</style>
