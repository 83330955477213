<template>
  <v-app>
    <v-app-bar
      class="appBar"
    >
      <v-app-bar-title>
        <div
          class="appBar__wrapper"
        >
          <router-link
            class="appBar__logo"
            to="/"
          >
            <koch-symbol
              class="appBar__logo__kochSymbol"
            />
            <koch-logo
              class="appBar__logo__kochLogo"
            />
          </router-link>
          <div
            class="appBar__title"
          >
            <span class="appBar__title__pxt">Pxt</span><span class="appBar__title__designPortal">{{t("appBarElement.appTitle")}}</span>
          </div>
        </div>
      </v-app-bar-title>
      <template v-slot:append>
        <div>
          <template
            v-for="(language, i) in ['de','en']"
            :key="i"
          >
            <span
              @click="() => {locale = ['de-DE', 'en-Us'][i]}"
              :class="{'text-decoration-underline' : locale === ['de-DE', 'en-Us'][i]} " id="language"
            >
              {{language.toUpperCase()}}
            </span>
            <template
              v-if="i === 0"
            >
              |
            </template>
          </template>
          <v-btn
            icon="mdi-dots-vertical"
            id="menu-activator"
          />
          <v-menu
            activator="#menu-activator"
            location="start"
          >
            <v-list>
                  <v-list-item>
                    <router-link to="/privacy-policy">{{t('appBarElement.settings.privacyPolicy')}}</router-link>
                  </v-list-item>
                  <v-list-item>
                    <router-link to="/legal-disclosure">{{t('appBarElement.settings.impressum')}}</router-link>
                  </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import kochLogo from './components/svgComponents/kochLogoLong.vue'
import kochSymbol from './components/svgComponents/kochSymbol.vue'
const { t, locale } = useI18n()

function goToHomepage () {
  const baseLinkToHomepage = 'https://auslegungsportal.bremsenergie.de/'
  window.open(baseLinkToHomepage)
}
</script>

<style scoped lang="scss">

a { text-decoration: none; }
  .appBar {
    background: linear-gradient(90deg, #dadada 0%, #f3f3f3 1%, #c0c0c0 100%);

      &__wrapper {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        width: 9rem
      }

    &__logo {
      display: flex;
      align-items: flex-end;
      cursor: pointer;
      &__kochLogo {
        width: 6rem;
        height: auto;
        margin-right: 0.5rem;
      }
      &__kochSymbol {
        width: 1.6rem;
        height: auto;
        margin-right: 0.5rem
      }
    }

    &__title {
      line-height: 0.8rem;
      &__pxt {
        font-family: 'Engravers\' Gothic BT';
        font-size: 1.2rem;
      }
      &__designPortal {
        font-size: 0.79rem
      }
    }
  }

  @font-face {
    font-family: "Engravers' Gothic BT";
    src: local("Merienda"),
    url('./fonts/Engravers Gothic BT/engravers\'-gothic-bt.ttf') format("truetype");
  }

  #language {
    cursor: pointer;
  }

  .v-application {
    overflow: hidden;
  }
</style>
