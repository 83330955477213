import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "inputArea" }
const _hoisted_2 = { class: "inputArea__inlineWrapper" }
const _hoisted_3 = { class: "inputArea__inlineWrapper__description" }

import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { inputParameterNames, inputProfileType } from 'koch-types/src/pxtDesignPortalFrontend'
// @ts-ignore
import { useMq } from 'vue3-mq'


export default /*@__PURE__*/_defineComponent({
  __name: 'sliderNumberInputTuple',
  props: {
    index: {},
    profile: {},
    inputKey: {},
    sliderValue: {},
    inputKeyMapping: {},
    ticks: {}
  },
  emits: ['update:sliderValue'],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "3024d316": (responsiveCSS.value.fontSize)
}))


const emit = __emit
const mq = useMq()

const props = __props

// helpers
const { t } = useI18n()
const store = useStore()

const inputChangeOrigin = ref('')

watch(store.state.slideIndex, async (currVal : {value: number}) => {
  if (currVal.value === 2) {
    store.commit('resetInputVals')
    inputChangeOrigin.value = 'input'
    inputValueComputed.value = store.state.inputValues[props.inputKey]
  }
})

function getClosestIndex (array : number[], value : number) {
  const diffArr = array.map(x => Math.abs((value) - x))
  const minNumber = Math.min(...diffArr)
  const index = diffArr.findIndex(x => x === minNumber)
  return index
}

const responsiveCSS = computed(() : {[cssDescriptor : string] : string} => {
  if (mq.smMinus) {
    return {
      fontSize: '16px'
    }
  } else {
    return {
      fontSize: '16px'
    }
  }
})

const inputValueComputed = computed({
  get () : number {
    return store.state.inputValues[props.inputKey]
  },
  set (value : number) {
    let sliderValueTemp
    if ((value === null || value === undefined) || isNaN(value)) {
      value = store.state.thresholds[props.inputKey].minVal
    }
    if (inputChangeOrigin.value === 'slider') {
      sliderValueTemp = value
      if (value % 1 === 0) {
        value = props.ticks[value]
      } else {
        const lowerTickIndex = Number((value - Number((value % 1).toFixed(1))).toFixed(0))
        const upperTickIndex = Number((value + Number((1 - (value % 1)).toFixed(1))).toFixed(0))

        const lowerTickValue = props.ticks[lowerTickIndex]
        const upperTickValue = props.ticks[upperTickIndex]

        const delta = Number((upperTickValue - lowerTickValue).toFixed(1))
        value = Number((lowerTickValue + (delta * (value % 1))).toFixed(1))
      }
    } else {
      sliderValueTemp = getClosestIndex(props.ticks, value)
    }

    if (value < store.state.thresholds[props.inputKey].minVal) {
      value = store.state.thresholds[props.inputKey].minVal
    } else if (value > store.state.thresholds[props.inputKey].maxVal) {
      value = store.state.thresholds[props.inputKey].maxVal
    }

    store.commit('updateInputVals', { type: props.inputKey, value: value })

    // general adaption of slider thumb position and dedicated input value
    emit('update:sliderValue', sliderValueTemp)

    if (props.inputKey === 'p0' || props.inputKey === 'tZ') {
      if (store.state.inputValues[props.inputKeyMapping[props.inputKey].counterPart] > (props.inputKeyMapping[props.inputKey].multiplier * value)) {
        // adaption to the upper and lower borders/thresholds of possible input values
        if (store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].minVal > props.inputKeyMapping[props.inputKey].multiplier * value) {
          store.commit('updateInputVals', { type: props.inputKeyMapping[props.inputKey].counterPart, value: store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].minVal }) // adapts dedicated counter value to thresholds
          store.commit('updateInputVals', { type: props.inputKey, value: 2 * store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].minVal }) // adapts interaction Values to thresholds
        } else {
          store.commit('updateInputVals', { type: props.inputKeyMapping[props.inputKey].counterPart, value: (props.inputKeyMapping[props.inputKey].multiplier * value) }) // adapts dedicated counter value
        }
      }
    } else {
      if (store.state.inputValues[props.inputKeyMapping[props.inputKey].counterPart] < (props.inputKeyMapping[props.inputKey].multiplier * value)) {
        // adaption to the upper and lower borders/thresholds of possible input values
        if ((store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].maxVal) < props.inputKeyMapping[props.inputKey].multiplier * value) {
          store.commit('updateInputVals', { type: props.inputKeyMapping[props.inputKey].counterPart, value: store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].maxVal }) // adapts dedicated counter value to thresholds
          store.commit('updateInputVals', { type: props.inputKey, value: 0.5 * store.state.thresholds[props.inputKeyMapping[props.inputKey].counterPart].maxVal }) // adapts interaction Values to thresholds
        } else {
          store.commit('updateInputVals', { type: props.inputKeyMapping[props.inputKey].counterPart, value: (props.inputKeyMapping[props.inputKey].multiplier * value) }) // adapts dedicated counter value
        }
      }
    }
  }
})

return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_slider = _resolveComponent("v-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.profile.inputTypeSign), 1),
        _createElementVNode("sub", null, _toDisplayString(_unref(t)("inputElement.depictor.index." + _ctx.profile.inputTypeSpecifier)), 1),
        _createElementVNode("sup", null, [
          _createVNode(_component_v_icon, {
            size: "x-small",
            color: "grey darken-4"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" mdi-help ")
            ])),
            _: 1
          })
        ]),
        _createVNode(_component_v_tooltip, {
          activator: "parent",
          location: "top"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(t)("inputElement.tooltip." + _ctx.profile.inputTypeDescription)), 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_v_text_field, {
        class: "inputArea__inlineWrapper__textField text-caption",
        placeholder: inputValueComputed.value.toString(),
        "persistent-placeholder": "",
        modelValue: inputValueComputed.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = (v) => {
            inputChangeOrigin.value = 'input';
            inputValueComputed.value = parseFloat(v.replace(',', '.'))
          }),
        density: "compact",
        variant: "underlined",
        step: _unref(store).state.thresholds[props.inputKey].stepSize,
        suffix: _ctx.profile.unit,
        "hide-details": ""
      }, null, 8, ["placeholder", "modelValue", "step", "suffix"])
    ]),
    _createVNode(_component_v_slider, {
      class: "inputArea__slider",
      modelValue: _ctx.sliderValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = (v) => {
          inputChangeOrigin.value = 'slider'
          inputValueComputed.value = v
        }),
      color: "#E30018",
      "track-color": "lightgrey",
      max: getClosestIndex(props.ticks, _unref(store).state.thresholds[props.inputKey].maxVal) + 1,
      min: 0,
      step: 0.1,
      "thumb-size": "15",
      "hide-details": ""
    }, null, 8, ["modelValue", "max"])
  ]))
}
}

})