import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ldWrapper" }
const _hoisted_2 = { class: "legalDisclosure" }
const _hoisted_3 = { class: "legalDisclosure__section__header" }
const _hoisted_4 = {
  key: 0,
  class: "legalDisclosure__section__content"
}
const _hoisted_5 = { class: "legalDisclosure__section__content__key" }
const _hoisted_6 = {
  key: 0,
  class: "legalDisclosure__section__content--breakStructure"
}
const _hoisted_7 = { class: "legalDisclosure__section__content__key legalDisclosure__section__content__key--breakStructure" }
const _hoisted_8 = { class: "legalDisclosure__section__content__value" }
const _hoisted_9 = {
  key: 1,
  class: "legalDisclosure__section__content"
}
const _hoisted_10 = { class: "legalDisclosure__section__content__key" }
const _hoisted_11 = { class: "legalDisclosure__section__content__value" }

import { useI18n } from 'vue-i18n'

export default /*@__PURE__*/_defineComponent({
  __name: 'legalDisclosureElement',
  setup(__props) {

const { t, locale } = useI18n()
const legalDisclosure = {
  owner: {
    company: 'Michael Koch GmbH',
    CEOs: 'Michael Koch, Christine Lieber-Koch',
    address: 'Zum Grenzgraben 28, 76698 Ubstadt-Weiher, Deutschland',
    telephone: '+49 7251 96 26-200',
    telefax: '+49 7251 96 26-210',
    Internet: 'www.bremsenergie.de',
    email: 'mail@bremsenergie.de',
    legalForm: 'GmbH',
    UStIdNr: 'DE 186 766 404',
    commercialRegister: 'HRB 231860 Registergericht Mannheim'
  },
  dataProtectionOfficer: 'dataProtectionOfficer',
  dataProtectionAuthority: 'dataProtectionAuthority',
  exclusionOfLiability: {
    content: 'content',
    links: 'links',
    copyright: 'copyright'
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(legalDisclosure, (value, key, index1) => {
        return _createElementVNode("div", {
          key: index1,
          class: "legalDisclosure__section"
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(t)('legalDisclosure.headings.' + key)), 1),
          (typeof value === 'string')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(t)('legalDisclosure.keys.' +
              value)), 1)
              ]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(value, (subValue, key, index2) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index2 }, [
                  (subValue === 'content' || subValue === 'links' || subValue ==='copyright')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)('legalDisclosure.keys.' + subValue)), 1),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('legalDisclosure.values.' + subValue)), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(t)('legalDisclosure.keys.' + key)), 1),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(subValue), 1)
                      ]))
                ], 64))
              }), 128))
        ])
      }), 64))
    ])
  ]))
}
}

})